import { useEffect } from 'react';
import styled from "styled-components/macro";
import { Flex } from "../../../../components/style/flex.component";
import fonts from "../../../../themes/fonts.theme";
import colors from "../../../../themes/colors-v2.theme";
import Text from "../../../../components/style/text.component";
import {
    languageMapping,
    languageMobileMapping,
    languages,
    updateLocaleLanguage,
} from "../../../../utils/locale/locale.utils";

interface Props {
    isOpen: boolean
    isFromSettings: boolean
    isResponsive: boolean
}

export const updateLanguage = (currentURL: URL, pathname: string, lang: string) => {
    updateLocaleLanguage(lang);
    for (let language of languages) {
        if (pathname.includes(`/${language}/`)) {
            pathname = pathname.substring(3);
        }
    }
    if (lang === 'en') {
        window.location.href = `${currentURL.origin}${pathname}`  
    } else {
        window.location.href = `${currentURL.origin}/${lang}${pathname}`
    }
}
const LanguageGestion = ({ isOpen, isFromSettings, isResponsive }: Props) => {
    const languageDictionary = isResponsive ? languageMobileMapping : languageMapping
    const currentURL = new URL(window.location.href);
    let pathname = currentURL.pathname;


    return (
        <>
        <Container>
            <Flex alignItems="center">
                <Label
                    style={{
                        fontSize: isResponsive? '13px' : '16px'
                    }}
                >
                    {languageDictionary[localStorage.getItem("language") ?? "en"]}
                </Label >
                <img
                    src="/assets/icons/nearby/chevron-right-white.svg"
                    alt="radar"
                    width={isResponsive? 7 : 12}
                    height={isResponsive? 7 : 12}
                    style={
                    isOpen
                        ? {
                            transform: isFromSettings? "rotate(90deg)" : "rotate(-90deg)",
                            cursor: "pointer",
                            transition: "all 400ms",
                        }
                        : { transform: isFromSettings ? "rotate(-90deg)" : "rotate(90deg)", transition: "all 400ms" }
                    }
                />
            </Flex>

            {isOpen && (
                <Menu 
                    style={{
                        transform: isFromSettings? 'translateY(-260px)' : 'translateY(-20px)'
                    }}
                >
                    {languages.map((lang) => {
                        return (
                            <MenuItem
                            onClick={() => updateLanguage(currentURL, pathname, lang)}
                            key={lang}
                            >
                            <Text>{languageDictionary[lang]}</Text>
                            </MenuItem>
                        );
                    })}
                </Menu>
            )}
        </Container>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Label = styled.p<{ outlined?: boolean }>`
    margin: 0 8px 0 0;
    font-family: ${fonts.semiBold.name};
    font-weight: 600;
    color: white;
`;

const Menu = styled.div`
    position: absolute;
    background-color: #1a1a1b;
    top: 50px;
    left: 0px;
    width: calc(100% - 2px);
    z-index: 999;
    border-radius: 4px;
    overflow-y: scroll;
    max-height: 200px;
    border: solid 1px ${colors.grayBorder};
`;

const MenuItem = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    background-color: ${colors.dark}
    border-bottom: solid 1px ${colors.grayBorder};
    &:hover {
        background-color: ${colors.grayBorder};
    }
    & > img {
        margin-right: 10px;
        width: 18px;
        height: 18px;
    }
    & > p {
        font-family: ${fonts.semiBold.name};
        font-weight: ${fonts.semiBold.weight};
        font-size: 12px;
        color: white;
    }
`;

export default LanguageGestion;
