import { debounce } from 'lodash';
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { store } from '../store/configure.store';
import { defaultHeaders } from '../services/utils.service';

const getNominatim = async (route: string) => {
  const token =
    store.getState().authStore.token || window.localStorage.getItem("token");

  const headers = { ...defaultHeaders };
  headers["Access-Control-Allow-Origin"] = "*";
  if (token) {
    headers["Authorization"] = token;
  }
  return await fetch(`https://nominatim.bearwww.com/${route}`, {
    method: "get",
    headers,
  });
};

export const debouncedCitySearch = (setAddressAutocomplete: (results: SearchResult[]) => void) => {
  return debounce(async (citySearch: string) => {
    try {
      const response = await getNominatim(`search?format=jsonv2&q=${encodeURIComponent(citySearch)}&addressdetails=1`);
      const results = await response.json();

      const cityResults: SearchResult[] = results
        .filter((result: any) =>
          result.address &&
          ['city', 'town', 'village', 'hamlet'].some(type => type in result.address)
        )
        .map((result: any) => ({
          x: parseFloat(result.lon),
          y: parseFloat(result.lat),
          label: result.display_name,
          bounds: result.boundingbox 
            ? [
              parseFloat(result.boundingbox[0]),
              parseFloat(result.boundingbox[2]),
              parseFloat(result.boundingbox[1]),
              parseFloat(result.boundingbox[3]),
            ]
            : null,
          raw: result
        }));

      setAddressAutocomplete(cityResults);
    } catch (error) {
      console.error('Error fetching city search results:', error);
      setAddressAutocomplete([]);
    }
  }, 500);
};

export const getCityFromPosition = async (latitude: number, longitude: number) => {
  try {
    const response = await getNominatim(
      `reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    );

    const data = await response.json();
    return data.address?.village ?? data.address?.town ?? data.address?.city ?? data.address?.municipality ?? '';
  } catch (e) {
    console.error(e);
  }
};
