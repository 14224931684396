import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { PrimaryButton } from "../../components/forms/primaryButton.component";
import RadioBox from "../../components/forms/radiobox.input";
import { Flex } from "../../components/style/flex.component";
import Text from "../../components/style/text.component";
import { environment } from "../../environments";
import {
  generateBillingParams,
  getUserBillingHistory,
  getUserSubscriptionState,
  getWebOffers,
} from "../../services/subscription.service";
import ModalSlice from "../../store/slices/modal.slice";
import colors from "../../themes/colors-v2.theme";
import { useLogin } from "../../utils/auth.utils";
import { getCurrentLanguage } from "../../utils/locale/hreflang.utils";
import useLocale from "../../utils/locale/locale.hook";
import { replace } from "../../utils/locale/locale.utils";
import { OfferItem } from "./premium.modal";

type SubscriptionDuration = "1W" | "1M" | "3M" | "6M" | "1Y";

export const mapDurationToValue = {
  "1W": "1WEEK",
  "1M": "1MONTH",
  "3M": "3MONTHS",
  "6M": "6MONTHS",
  "1Y": "1YEAR",
};

type Offer = {
  price: string;
  duration: SubscriptionDuration;
  mostPopular: boolean;
};

export enum FormStatus {
  IDLE = "IDLE",
  LOADING_PARAMS = "LOADING_PARAMS",
  PAYMENT_READY = "PAYMENT_READY",
  ANNULATION_READY = "ANNULATION_READY",
}

export const PremiumScreenV2 = () => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const { me } = useLogin();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const [paymentParams, setPaymentParams] = useState<
    Array<{ name: string; value: string }>
  >([]);

  const [offers, setOffers] = useState([]);
  const [activeTab] = useState<"subscription" | "oneShot">("oneShot");
  const [selectedOffer, setSelectedOffer] = useState<Offer["duration"]>("3M");
  const [paymentMethod, setPaymentMethod] = useState<
    "creditCard" | "amex" | "transfer" | "paypal" | "cheque"
  >("creditCard");
  const [cardType, setCardType] = useState<"CB" | "AMEX">("CB");
  const [payboxUrl, setPayboxUrl] = useState('');

  const [isBillingHistoryShown, setIsBillingHistoryShown] = useState(false);
  const [billingHistory, setBillingHistory] = useState([]);
  const [, setSubscription] = useState(null);
  const [, setHasCodeSubscription] = useState(false);


  const getPaymentParamsHandler = async (newBillingForm) => {

    await generateBillingParams(newBillingForm).then((res) => {
      if (res?.success) {
        setPaymentParams(res.params);
      }
    });
  };

  useEffect(() => {
    const newBillingForm = {
      subscription: false,
      lang: getCurrentLanguage(),
      email: me.email,
      userID: me.id,
      duration: mapDurationToValue[selectedOffer],
      cardType: cardType
    };

    getPaymentParamsHandler(newBillingForm);
  }, [selectedOffer, cardType]);

  useEffect(() => {
    const updateSubscription = async () => {
      const userSubscription = await getUserSubscriptionState();
      setSubscription(
        userSubscription && userSubscription.isActive ? userSubscription : null
      );
      setHasCodeSubscription(
        userSubscription &&
          userSubscription.isActive &&
          userSubscription.paymentMethod === "PROMOTIONAL_CODE"
      );

      const userBillingHistory = await getUserBillingHistory();
      const userBillingHistoryWithDates = userBillingHistory?.map?.((bill) => {
        const now = new Date();
        const endDate = new Date(bill.end_date);
        return {
          ...bill,
          endDatePassed: endDate < now,
          isCurrentPlan:
            now >= new Date(bill.start_date) &&
            (!bill.end_date || endDate > now),
        };
      });
      setBillingHistory(userBillingHistoryWithDates);
    };

    updateSubscription();
  }, [me]);

  const closeModalHandler = () => {
    dispatch(ModalSlice.actions.setPremium({ visible: false }));
  };

  const handleSelectOffer = (offerDuration: Offer["duration"]) => {
    setSelectedOffer(offerDuration);
  };

  const preventCloseModalHandler = (e) => {
    e.stopPropagation();
    return;
  };

  useEffect(() => {
    getWebOffers().then((_offers) => setOffers(_offers));
    getPayboxUrlHandler()
  }, []);

  const activeTabStyle = {
    background: colors.darkBlue,
    padding: 8,
    borderRadius: 8,
    paddingRight: 32,
  };

  useEffect(() => {
    if (paymentMethod == "amex") {
      setCardType("AMEX")
    }
    if (paymentMethod == "creditCard") {
      setCardType("CB")
    }
  }, [paymentMethod])

  const getPayboxUrlHandler = async() => {
    const payboxPrimaryPage = await fetch("https://tpeweb.paybox.com/load.html")
    const classicOrResponsive = isDesktop ? environment.payboxUrl.classic : environment.payboxUrl.responsive
    const chosenPayboxUrl = payboxPrimaryPage.status === 200 ? classicOrResponsive.primary : classicOrResponsive.secondary
    setPayboxUrl(chosenPayboxUrl)
  }

  const defaultTabStyle = { padding: 8, paddingRight: 32 };

  return (
    <Container>
      {!isBillingHistoryShown && (
        <Fragment>
          <Header>
            <Text bold fontSize="20px" style={{ textAlign: "center" }}>
              {locale("subscription.benefits")}
              <img src="/assets/icons/logo/bearwwx.svg" />{" "}
            </Text>
            <GridContainer>
              <GridRow>
                <img
                  src="/assets/icons/premium/unlimited.svg"
                  alt="unlimited"
                />
                <Text>
                  {locale("subscription.benefits.unlimited_profiles")}
                </Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/receipt.svg" alt="receipt" />
                <Text>{locale("subscription.benefits.receipt")}</Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/visitors.svg" alt="visitors" />
                <Text>{locale("subscription.benefits.visits")}</Text>
              </GridRow>
              <GridRow>
                <img
                  src="/assets/icons/premium/ephemeral.svg"
                  alt="ephemeral"
                />
                <Text>{locale("subscription.benefits.ephemeral")}</Text>
              </GridRow>
              <GridRow>
                <img
                  src="/assets/icons/premium/filters-line.svg"
                  alt="filters"
                />
                <Text>{locale("subscription.benefits.filters")}</Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/typing.svg" alt="typing" />
                <Text>{locale("subscription.benefits.typing")}</Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/photo.svg" alt="photo" />
                <Text>{locale("subscription.benefits.photos")}</Text>
              </GridRow>
              <GridRow>
                <img src="/assets/icons/premium/album.svg" alt="album" />
                <Text>{locale("subscription.benefits.albums-share")}</Text>
              </GridRow>
              <GridRow>
                <img
                  src="/assets/icons/premium/incognito.svg"
                  alt="incognito"
                />
                <Text>{locale("subscription.benefits.incognito")}</Text>
              </GridRow>
            </GridContainer>
          </Header>
          <Content>
            <Subscription>
              <Flex direction="column" alignItems="center" gap="14px">
                <div
                  style={{
                    border: `1px solid ${colors.grayBorder}`,
                    padding: 8,
                    borderRadius: 1000,
                    width: 24,
                    height: 24,
                  }}
                >
                  <img
                    src="/assets/icons/premium/credit-card.svg"
                    alt="credit-card"
                    width={24}
                    height={24}
                  />
                </div>
                <Text bold fontSize="20px">
                  {locale("subscription.choose")}
                </Text>
                <Text bold fontSize="14px">
                  {locale("subscription.payment.mode")}
                </Text>
              </Flex>
              <div style={{ marginTop: 24, marginLeft: 24, marginRight: 24 }}>
                {/* <FilterRow onClick={preventCloseModalHandler}>
                  <FilterButton
                    active={activeTab === "subscription"}
                    onClick={() => {
                      setActiveTab("subscription");
                    }}
                  >
                    {locale("global.settings.memberships")}
                  </FilterButton>
                  <FilterButton
                    active={activeTab === "oneShot"}
                    onClick={() => {
                      setActiveTab("oneShot");
                    }}
                  >
                    {locale("premium.category.one_shot")}
                  </FilterButton>
                </FilterRow> */}
                {activeTab === "oneShot" ? (
                  <Flex
                    gap="8px"
                    justify="center"
                    style={{
                      marginTop: 20,
                      flexWrap: "wrap",
                      marginBottom: 12,
                    }}
                  >
                    <RadioBox
                      style={
                        paymentMethod === "creditCard"
                          ? activeTabStyle
                          : defaultTabStyle
                      }
                      size="small"
                      value={paymentMethod === "creditCard"}
                      onChange={() => setPaymentMethod("creditCard")}
                      text={locale("subscription.payment_method.creditCard")}
                    ></RadioBox>
                    <RadioBox
                    style={
                      paymentMethod === "amex"
                        ? activeTabStyle
                        : defaultTabStyle
                    }
                    size="small"
                    value={paymentMethod === "amex"}
                    onChange={() => setPaymentMethod("amex")}
                    text={"American Express"}
                  ></RadioBox>
                    <RadioBox
                      style={
                        paymentMethod === "transfer"
                          ? activeTabStyle
                          : defaultTabStyle
                      }
                      size="small"
                      value={paymentMethod === "transfer"}
                      onChange={() => setPaymentMethod("transfer")}
                      text={locale("subscription.payment_method.transfer")}
                    ></RadioBox>
                    <RadioBox
                      style={
                        paymentMethod === "paypal"
                          ? activeTabStyle
                          : defaultTabStyle
                      }
                      size="small"
                      value={paymentMethod === "paypal"}
                      onChange={() => setPaymentMethod("paypal")}
                      text={locale("subscription.payment_method.paypal")}
                    ></RadioBox>
                    <RadioBox
                      style={
                        paymentMethod === "cheque"
                          ? activeTabStyle
                          : defaultTabStyle
                      }
                      size="small"
                      value={paymentMethod === "cheque"}
                      onChange={() => setPaymentMethod("cheque")}
                      text={locale("subscription.payment_method.cheque")}
                    ></RadioBox>
                  </Flex>
                ) : null}
                {paymentMethod === "creditCard" || paymentMethod === "amex" ||
                activeTab === "subscription" ? (
                  <div>
                    <Offers onClick={preventCloseModalHandler}>
                      {offers.map((offer) => (
                        <div
                          key={offer.duration}
                          onClick={() => handleSelectOffer(offer.duration)}
                          style={{ cursor: "pointer" }}
                        >
                          <OfferItem
                            offer={offer}
                            onClick={closeModalHandler}
                            activeTab={activeTab}
                            isDesktop={isDesktop}
                            isSelected={offer.duration === selectedOffer}
                          />
                        </div>
                      ))}
                    </Offers>

                    <FormWrapper>
                      <form
                        method="post"
                        action={`${payboxUrl}`}
                        id="paymentParams"
                      >
                        {paymentParams.map((param) => (
                          <input
                            key={param.name}
                            name={param.name}
                            value={param.value}
                            type="hidden"
                          />
                        ))}
                      </form>

                      <PrimaryButton
                        isActive
                        style={{ width: "100%" }}
                        size="large"
                        type="submit"
                        value="Submit"
                        form="paymentParams"
                      >
                        {locale("grid.premium.subscribe")}
                      </PrimaryButton>
                    </FormWrapper>
                  </div>
                ) : (
                  <CheckInstructions
                    dangerouslySetInnerHTML={{
                      __html: replace(locale("premium.check.instructions"), {
                        pseudo: me.pseudo,
                        email: me.email,
                      }),
                    }}
                    onClick={preventCloseModalHandler}
                  />
                )}

                <Flex
                  alignItems="center"
                  justify="space-between"
                  style={{ marginBottom: 16, marginTop: 24 }}
                >
                  <Flex direction="column" style={{ flex: 1, gap: 12 }}>
                    <PaymentRow>
                      <img
                        src="/assets/icons/check-outlined-white.svg"
                        width={16}
                        height={16}
                      />
                      <div style={{ lineHeight: "16px" }}>
                        {locale("messages.shoutout.payment_1")}
                      </div>
                    </PaymentRow>
                    <PaymentRow>
                      <img
                        src="/assets/icons/check-outlined-white.svg"
                        width={16}
                        height={16}
                      />
                      <div style={{ lineHeight: "16px" }}>
                        {locale("messages.shoutout.payment_2")}
                      </div>
                    </PaymentRow>
                    <PaymentRow>
                      <img
                        src="/assets/icons/check-outlined-white.svg"
                        width={16}
                        height={16}
                      />
                      <div style={{ lineHeight: "16px" }}>
                        {locale("messages.shoutout.payment_3")}
                      </div>
                    </PaymentRow>
                  </Flex>
                  <PaymentIconsGrid>
                    <img
                      src="/assets/icons/messages/mastercard.svg"
                      width={32}
                      height={32}
                    />
                    <img
                      src="/assets/icons/messages/visa.svg"
                      width={32}
                      height={32}
                    />
                    <img
                      src="/assets/icons/messages/american-express.svg"
                      width={32}
                      height={32}
                    />
                    <img
                      src="/assets/icons/messages/CB.svg"
                      width={32}
                      height={32}
                    />
                  </PaymentIconsGrid>
                </Flex>
              </div>
            </Subscription>
            <RightSide>
              <Card>
                {/* <Text bold fontSize="20px">
                  {locale("subscription.payment.allopass")}
                </Text> */}
                <Text bold fontSize="14px" color={colors.darkText}>
                  {locale("subscription.benefits.one-day")}
                </Text>

                {/* script */}
                <Flex alignItems="center" gap="4px">
                  <img
                    src="/assets/icons/check-outlined-white.svg"
                    width={16}
                    height={16}
                  />
                  <div
                    style={{
                      lineHeight: "16px",
                      fontSize: 10,
                      color: colors.lightGrey7,
                    }}
                  >
                    {locale("messages.shoutout.payment_3")}
                  </div>
                </Flex>
              </Card>
              <Card style={{ alignItems: "flex-start" }}>
                <Text bold fontSize="20px">
                  {locale("account.settings.billing_history.title")}
                </Text>
                <Text bold fontSize="14px" color={colors.darkText}>
                  {locale("account.settings.billing_history.access")}
                </Text>
                <PrimaryButton
                  size="large"
                  style={{ background: "white", color: colors.black }}
                  onClick={() => setIsBillingHistoryShown(true)}
                >
                  {locale(
                    "account.settings.billing_history.display_history_button"
                  )}
                </PrimaryButton>
              </Card>
              <Card style={{ alignItems: "flex-start" }}>
                <div
                  style={{
                    border: `1px solid ${colors.grayBorder}`,
                    padding: 8,
                    borderRadius: 1000,
                    width: 24,
                    height: 24,
                  }}
                >
                  <img
                    src="/assets/icons/user-profile/premiumX.svg"
                    alt="premium"
                    width={24}
                    height={24}
                  />
                </div>

                <Text bold fontSize="20px">
                  {locale("account.settings.billing_history.current_plan")}
                </Text>

                <Text bold fontSize="18px">
                  {billingHistory?.length > 0 &&
                    billingHistory?.[0].end_date && (
                      <Text>
                        {billingHistory?.[0].endDatePassed
                          ? !billingHistory?.[0].isOneShot
                            ? locale("account.settings.billing_history.expired")
                            : locale("account.settings.billing_history.expires")
                          : locale("account.settings.billing_history.renews")}
                        {" "}
                        {moment(billingHistory?.[0].end_date).format(
                          "YYYY/MM/DD"
                        )}
                      </Text>
                    )}
                </Text>

                <Text bold fontSize="14px" color={colors.darkText}>
                  {locale("account.settings.premium.active_plan")}
                </Text>
              </Card>
            </RightSide>
          </Content>
        </Fragment>
      )}
      {isBillingHistoryShown && billingHistory?.length && (
        <div>
          <img
            src="/assets/icons/nearby/chevron-right-white.svg"
            alt="chevron"
            style={{
              transform: "rotate(180deg)",
              cursor: "pointer",
              padding: 4,
            }}
            width={24}
            height={24}
            onClick={() => setIsBillingHistoryShown(false)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {billingHistory.map((bill, idx) => (
              <SubSection key={idx}>
                <Row>
                  <SubSectionLeft>
                    <Flex justify="space-between" alignItems="center">
                      <Text
                        style={{
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "18px",
                        }}
                      >
                        {moment(bill.start_date).format("YYYY/MM/DD")}
                        {bill.end_date && (
                          <>
                            {" "}
                            {locale("account.settings.billing_history.to")}
                            {" "}
                            {moment(bill.end_date).format("YYYY/MM/DD")}
                          </>
                        )}
                      </Text>
                      <div>
                        {bill.isCurrentPlan ? (
                          <PrimaryButton
                            style={{
                              background: "white",
                              color: colors.dark,
                              cursor: "default",
                              height: 34,
                              fontWeight: 500,
                            }}
                          >
                            <div
                              style={{
                                width: 6,
                                height: 6,
                                background: "#17B26A",
                                borderRadius: "100%",
                              }}
                            ></div>
                            {locale("subscription.status.active")}
                          </PrimaryButton>
                        ) : (
                          ""
                        )}
                      </div>
                    </Flex>
                    <Text bold fontSize="14px" color={colors.darkText}>
                      {locale(
                        "account.settings.billing_history.pro"
                      )?.toUpperCase()}
                      {" - "}
                      {locale(
                        `account.settings.billing_history.${bill.duration}`
                      )}
                      {`(${
                        bill.isOneShot
                          ? locale(
                              "account.settings.billing_history.one_shot_payment"
                            )
                          : locale(
                              "account.settings.billing_history.auto_renew"
                            )
                      })`}
                    </Text>
                    <Text style={{ fontWeight: 700 }} fontSize="14px">
                      {!bill.amount_including_tax ? (
                        <>
                          {locale(
                            "account.settings.billing_history.price_offered"
                          )}
                        </>
                      ) : (
                        <>{`${bill.amount_including_tax} ${bill.currency}`}</>
                      )}
                    </Text>
                    {bill.isCurrentPlan && (
                      <Text bodyBold>
                        {locale(
                          "account.settings.billing_history.current_plan"
                        )}
                      </Text>
                    )}
                    {bill.end_date && (
                      <Text>
                        {bill.endDatePassed
                          ? !bill.isOneShot
                            ? locale("account.settings.billing_history.expired")
                            : locale("account.settings.billing_history.expires")
                          : locale(
                              "account.settings.billing_history.renews"
                            )}{" "}
                        {moment(bill.end_date).format("YYYY/MM/DD")}
                      </Text>
                    )}
                  </SubSectionLeft>
                </Row>
              </SubSection>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 12px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #717171;
  margin-bottom: 16px;
  & > * {
    margin-bottom: 16px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  background: linear-gradient(209deg, #ff6006 9.53%, #c86000 111.75%);
  box-shadow: 0px -12px 28px 0px rgba(0, 0, 0, 0.25) inset,
    0px 10px 20px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(12px);
  padding: 25px;
  border-radius: 16px;
`;

const GridContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`;

const GridRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Content = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 12px;
`;

const Card = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.graySeparator2};
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.4);
  gap: 8px;
`;

const Subscription = styled(Card)`
  flex: 2;
  height: fit-content;
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : "white")};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${colors.darkBlue}` : ""};
  width: 100%;
`;

const FilterRow = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 8px;
  gap: 8px;
`;

const Row = styled.div`
  padding: 24px;
  display: flex;
  border: 1px solid ${colors.graySeparator2};
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.4);
  gap: 8px;
`;

const Offers = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  box-sizing: border-box;
  padding: 21px 0px;

  @media (max-width: 1000px) {
    padding: 21px 12px;
  }
`;

const Offer = styled.div<{ mostPopular?: boolean; selected?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px
    ${({ selected }) => (selected ? colors.darkBlue : colors.lightGrey2)};
  box-sizing: border-box;
  border-radius: 8px;
  background: ${({ selected }) => (selected ? "#106BF280" : colors.dark)};
`;

const PaymentRow = styled.div`
  gap: 4px;
  border: none;
  border-radius: 8px;
  height: 100%;
  color: ${colors.white};
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 14px;
  padding: 0 12px;
`;

const PaymentIconsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;

const CheckInstructions = styled.div`
  margin-top: 12px;
  position: relative;
  margin-bottom: 8px;
  padding: 0px 38px 24px 38px;
  border-radius: 4px;
  white-space: pre-wrap;
  overflow-y: auto;
  font-size: 12px;
  max-height: calc(100vh - 200px);

  a {
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    max-height: 100vh;
    padding-top: 24px;
  }
`;

export const SubSection = styled.div`
  padding: 16px 0;
  width: 100%;
  max-width: 800px;
`;

const SubSectionLeft = styled.div`
  grid-column-start: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
`;

const Label = styled.p`
  color: ${colors.lightGrey12};
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 4px;
`;

const TransparentButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  padding: 0;
`;
