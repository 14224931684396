import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  boostModalVisible: false,
  moderationProfilePictureVisible: false,
  createPostVisible: false,
  createPostOpenPhoto: false,
  signup: {
    visible: false,
    initialPseudo: '',
  },
  signin: {
    canCloseSignin: true,
  },
  signinVisible: false,
  moderationVisible: false,
  editPicturesVisible: false,
  editPictures: {
    album: 'myPhotos',
  },
  userProfileVisible: false,
  isFromSharedAlbum: undefined,
  isUserProfileAnonymousAndPseudo: {
    isAnonymous: false,
    pseudo: '',
    userProfileVisible: false,
    isFromSharedAlbum: false,
  },
  fullSizeImageVisible: false,
  fullsizeImage: {
    images: [],
    start: 0,
    timeLeft: 0,
    type: 'pictures',
    isLikeable: false,
  },
  likesDetailsVisible: false,
  likesDetails: {
    likes: [],
  },
  updatePostVisible: false,
  updatePost: {
    post: {},
  },
  resetPasswordVisible: false,
  accountActivationVisible: false,
  submitReportVisible: false,
  submitReport: {
    type: null,
    key: null, // post slug, user id, live id, ...
  },
  sanctionAppealVisible: false,
  sanctionAppeal: {
    type: null,
    key: null, // sanction id
    canAppealSanction: true,
  },
  blockUserVisible: false,
  blockUser: {
    userId: null,
  },
  notification: {
    visible: false,
    title: '',
  },
  selectPictures: {
    visible: false,
    type: null,
    onSelectedPictures: null,
    onSelectedFiles: null,
  },
  selectUserPictures: {
    visible: false,
    onPicturesConfirm: null,
  },
  answerCall: {
    visible: false,
    callId: null,
    user: null,
  },
  videoCall: {
    visible: false,
    callId: null,
    timeRemaining: null,
  },
  premium: {
    visible: false,
  },
  premiumSuccess: {
    visible: false,
  },
  premiumError: {
    visible: false,
  },
  liveTerms: {
    visible: false,
  },
  sexualContentWarning: {
    visible: false,
  },
};

const ModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setBoostModalVisible: (state, { payload }) => {
      return {
        ...initialState,
        boostModalVisible: payload,
      };
    },
    setBillingAddressVisible: (state, { payload }) => {
      return {
        ...initialState,
        billingAddressVisible: payload,
      };
    },
    setShoutoutModalVisible: (state, { payload }) => {
      return {
        ...initialState,
        shoutoutModalVisible: payload,
      };
    },
    setModerationProfilePictureVisible: (state, { payload }) => {
      return {
        ...initialState,
        moderationProfilePictureVisible: payload,
      };
    },
    setUpdatePostVisible: (state, { payload }) => {
      return {
        ...initialState,
        updatePostVisible: payload.visible,
        updatePost: {
          post: payload.post,
        },
      };
    },
    setLikesDetailsVisible: (state, { payload }) => {
      return {
        ...initialState,
        likesDetailsVisible: payload.visible,
        likesDetails: {
          likes: payload.likes,
        },
      };
    },
    setCreatePostVisible: (state, { payload }) => {
      return {
        ...initialState,
        createPostOpenPhoto: payload.photo,
        createPostVisible: payload.visible,
      };
    },
    setFullSizeImageVisibleVisible: (state, { payload }) => {
      return {
        ...state,
        fullSizeImageVisible: payload.visible,
        fullsizeImage: {
          images: payload.images,
          start: payload.start,
          timeLeft: payload.timeLeft,
          type: payload.type,
          isLikeable: payload.isLikeable,
        },
      };
    },
    setSignup: (state, { payload }) => {
      return {
        ...initialState,
        signup: {
          visible: payload.visible,
          initialPseudo: payload.initialPseudo || '',
        },
      };
    },
    setSigninVisible: (state, { payload }) => {
      return {
        ...initialState,
        signinVisible: payload,
        signin: {
          canCloseSignin: true,
        },
      };
    },
    setSignin: (state, { payload }: { payload: { visible: boolean; canCloseSignin: boolean } }) => {
      return {
        ...initialState,
        signinVisible: payload.visible,
        signin: {
          canCloseSignin: payload.canCloseSignin,
        },
      };
    },
    setModerationVisible: (state, { payload }) => {
      return {
        ...initialState,
        moderationVisible: payload,
      };
    },
    setEditPicturesVisible: (state, { payload }: { payload: { visible: boolean; album: string } }) => {
      return {
        ...state,
        editPicturesVisible: payload.visible,
        editPictures: {
          album: payload.album,
        },
      };
    },
    setEditPicturesVisibleFromEditProfile: (state, { payload }) => {
      return {
        ...initialState,
        editProfilVisible: true,
        editPicturesVisible: payload.visible,
        editPictures: {
          album: payload.album,
        },
      };
    },
    setUserProfileVisible: (state, { payload }) => {
      return {
        ...initialState,
        userProfileVisible: payload.visible,
        isFromSharedAlbum: payload.isFromSharedAlbum,
      };
    },
    setIsUserProfileAnonymousAndPseudo: (state, { payload }) => {
      return {
        ...initialState,
        isUserProfileAnonymousAndPseudo: payload,
      };
    },
    setResetPasswordVisible: (state, { payload }) => {
      return {
        ...initialState,
        resetPasswordVisible: payload,
      };
    },
    setAccountActivationVisible: (state, { payload }) => {
      return {
        ...initialState,
        accountActivationVisible: payload,
      };
    },
    setSubmitReport: (
      state,
      {
        payload,
      }: {
        payload: {
          visible: boolean;
          type: 'post' | 'user' | 'live' | 'comment';
          key: string | number;
        };
      }
    ) => {
      return {
        ...state,
        submitReportVisible: payload.visible,
        submitReport: {
          type: payload.type,
          key: payload.key,
        },
      };
    },
    setSanctionAppeal: (
      state,
      {
        payload,
      }: {
        payload: {
          visible: boolean;
          type: string;
          key: number | string;
          canAppealSanction?: boolean;
        };
      }
    ) => {
      return {
        ...state,
        sanctionAppealVisible: payload.visible,
        sanctionAppeal: {
          type: payload.type,
          key: payload.key,
          canAppealSanction: payload.canAppealSanction ?? true,
        },
      };
    },
    setBlockUser: (state, { payload }: { payload: { visible: boolean; userId: string } }) => {
      return {
        ...state,
        blockUserVisible: payload.visible,
        blockUser: {
          userId: payload.userId,
        },
      };
    },
    setNotification: (state, { payload }: { payload: { visible: boolean; title: string } }) => {
      return {
        ...state,
        notification: {
          visible: payload.visible,
          title: payload.title,
        },
      };
    },
    setSelectPictures: (
      state,
      {
        payload,
      }: {
        payload: {
          visible: boolean;
          type: 'file' | 'upload';
          onSelectedPictures?: (pictures: Array<{ path: string; isVideo?: boolean }>) => void;
          onSelectedFiles?: (pictures: File[]) => void;
          onSelectedAlbum?: (pictures: any[]) => void;
        };
      }
    ) => {
      return {
        ...state,
        selectPictures: {
          visible: payload.visible,
          type: payload.type,
          onSelectedFiles: payload.onSelectedFiles || null,
          onSelectedPictures: payload.onSelectedPictures || null,
          onSelectedAlbum: payload.onSelectedAlbum || null,
        },
      };
    },
    setSelectUserPictures: (
      state,
      {
        payload,
      }: {
        payload: {
          visible: boolean;
          onPicturesConfirm: (pictures: any[]) => void;
        };
      }
    ) => {
      return {
        ...state,
        selectUserPictures: {
          visible: payload.visible,
          onPicturesConfirm: payload.onPicturesConfirm,
        },
      };
    },
    setVideoCall: (
      state,
      {
        payload,
      }: {
        payload: {
          visible: boolean;
          callId: number;
          timeRemaining: number;
          token: string;
        };
      }
    ) => {
      return {
        ...state,
        videoCall: payload,
      };
    },
    setAnswerCall: (state, { payload }: { payload: { visible: boolean; callId: number; user: any } }) => {
      return {
        ...state,
        answerCall: {
          visible: payload.visible,
          callId: payload.callId,
          user: payload.user,
        },
      };
    },
    setPremium: (state, { payload }: { payload: { visible: boolean } }) => {
      return {
        ...state,
        premium: {
          visible: payload.visible,
        },
      };
    },
    setPremiumSuccess: (state, { payload }: { payload: { visible: boolean } }) => {
      return {
        ...state,
        premiumSuccess: {
          visible: payload.visible,
        },
      };
    },
    setPremiumError: (state, { payload }: { payload: { visible: boolean } }) => {
      return {
        ...state,
        premiumError: {
          visible: payload.visible,
        },
      };
    },
    setLiveTerms: (state, { payload }: { payload: boolean }) => {
      return {
        ...state,
        liveTerms: {
          visible: payload,
        },
      };
    },
    setSexualContentWarning: (state, { payload }: { payload: { visible: boolean; redirectTo: string } }) => {
      return {
        ...state,
        sexualContentWarning: {
          visible: payload.visible,
          redirectTo: payload.redirectTo,
        },
      };
    },
  },
});

export default ModalSlice;
