import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Cookies from "universal-cookie";

import "moment/locale/ca";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/pt";

import SignupModal from "./auth/signup/signup.modal";
// DEBUG: might be a first version, will be deleted I think
// import ViewsScreen from "./views/views.screen";

import AccountActivationBanner from "../components/miscellaneous/account-activation-banner.component";
import ProfilePictureModeration from "../components/profile-picture-moderation/profile-picture-moderation.component";
import UpdatePostModal from "./feed/post/update-post.component";
// import EditPicturesModal from "./navbar/overlay-acount/edit-pictures/edit-pictures.component";
import Hreflang from "../components/seo/hreflang.component";
import QuickChatAccessOverlay from "./messages/components/quick-access/quick-access.overlay";

import FullSizeImagesModal from "../components/full-size-image/full-size-image.modal";
import ActivationModal from "../components/miscellaneous/modals/account-activation.modal";
import ResetPasswordModal from "../components/miscellaneous/modals/reset-password.modal";
import SanctionAppealModal from "../components/miscellaneous/modals/sanction-appeal.modal";
import SigninModal from "./auth/signin/signin.modal";
import CreatePostModal from "./feed/post/create-post.modal";
import ModerationModal from "./moderation/moderation.modal";
import UserProfileModal from "./user-profile/user-profile.modal";
import AnswerCallModal from "./video-call/answer-call.modal";
import VideoCallModal from "./video-call/video-call.modal";

import { useMediaQuery } from "react-responsive";
import styled from "styled-components/macro";
import { ModalNotificationSuccess } from "../components/miscellaneous/modals/notification-blocked-sucess.modal";
import { signinWithCookies } from "../services/auth.service";
import AuthSlice from "../store/slices/auth.slice";
import NavbarSlice from "../store/slices/navbar.slice";
import NotifSlice from "../store/slices/notification.slice";
import colors from "../themes/colors-v2.theme";
import { setMe, useLogin } from "../utils/auth.utils";
import { getUrlLanguage } from "../utils/locale/hreflang.utils";
import {
  getDefaultLanguage,
  updateLocaleLanguage,
} from "../utils/locale/locale.utils";
import { useRedirectUnsignedUser } from "../utils/useRedirectUnsignedUser";
import OverlayClassification from "./classification/classification.overlay";
import OverlayFavorites from "./favorites/favorites.overlay";
import { LiveTermsModal } from "./live/components/start/live-terms.modal";
import { WarningModal } from "./live/warningModal.component";
import SidebarDesktop from "./navbar/overlay-acount/sidebar-desktop.component";
import MobileSidebar from "./navbar/sidebar-mobile.component";
import OverlayNotifications from "./notifications/overlay-notifications.component";
import { PremiumErrorModal } from "./premium/premium.error.modal";
import { PremiumModal } from "./premium/premium.modal";
import { PremiumSuccessModal } from "./premium/premium.success.modal";
import { RoutesWrapper } from "./routes-wrapper";
import OverlayViews from "./views/views.overlay";
import { BoostModal } from "../components/boost-modal";
import SelectPictureModal from "../components/miscellaneous/modals/select-picture.modal";
import { BillingAddressModal } from "./messages/components/billing-address.component";
import { ShoutoutModal } from "./messages/components/shoutout-modal.component";

const timeout = null;

const PagesNavigation = () => {
  useRedirectUnsignedUser();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const modalStore = useSelector((state: any) => state.modalStore);
  const navbarStore = useSelector((state: any) => state.navbarStore);
  const chatStore = useSelector((state: any) => state.chatStore);
  const viewsStore = useSelector((state: any) => state.viewsStore);
  const favoriteStore = useSelector((state: any) => state.favoriteStore);
  const notifStore = useSelector((state: any) => state.notifStore);
  const classificationStore = useSelector(
    (state: any) => state.classificationStore
  );
  const { me, isLogin } = useLogin();
  const dispatch = useDispatch();
  const urlLanguage = getUrlLanguage();
  const [isBoostOpen, setIsBoostOpen] = useState(false);
  const [isBillingAddressOpen, setIsBillingAddressOpen] = useState(false);
  const [isShoutoutModalOpen, setIsShoutoutModalOpen] = useState(false);

  const [showSignupSignin, setShowSignupSignin] = useState(false);
  const previousScrollTop = useRef(0);

  useEffect(() => {
    if (urlLanguage) {
      localStorage.setItem("language", urlLanguage);
    }
    updateLocaleLanguage(
      urlLanguage || localStorage.getItem("language") || getDefaultLanguage()
    );
  }, []);

  useEffect(() => {
    if (isLogin) {
      if (!localStorage.getItem("hasAlreadyBeenLogged")) {
        localStorage.setItem("hasAlreadyBeenLogged", "1");
      }
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin) {
      if (!!me.id && !me.accountActivated) {
        /*timeout = setTimeout(
          () =>
            dispatch(NavbarSlice.actions.setShowAccountActivationBanner(true)),
          1500
        );*/
      } else {
        if (timeout) {
          clearTimeout(timeout);
        }
        dispatch(NavbarSlice.actions.setShowAccountActivationBanner(false));
      }
    }
  }, [me, isLogin, dispatch]);

  useEffect(() => {
    (async () => {
      const cookies = new Cookies();
      if (cookies.get("cookyid")) {
        const res = await signinWithCookies(
          cookies.get("cookyid"),
          cookies.get("cookynick"),
          cookies.get("cookystyle"),
          cookies.get("cookysort")
        );
        if (res.jwt) {
          localStorage.setItem("onboarding", "ok");
          localStorage.setItem("token", res.jwt);
          cookies.set("token", res.jwt, {
            domain: ".bearwww.com",
            path: "/",
            secure: true,
          });
          dispatch(AuthSlice.actions.setToken(res.jwt));
          setMe();
        }
        cookies.remove("cookyid");
      }
    })();
  }, []);

  useEffect(() => {
    let rafId: number;
    const handleScroll = (): void => {
      rafId = requestAnimationFrame(() => {
        const scrollTop = window.scrollY;
        if (scrollTop > previousScrollTop.current) {
          setShowSignupSignin(false);
        } else {
          setShowSignupSignin(true);
        }
        previousScrollTop.current = scrollTop;
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(rafId);
    };
  }, []);

  const overlayOpen =
    modalStore.moderationProfilePictureVisible ||
    modalStore.signup.visible ||
    modalStore.signinVisible ||
    modalStore.moderationVisible ||
    modalStore.editPicturesVisible ||
    modalStore.userProfileVisible ||
    modalStore.isUserProfileAnonymousAndPseudo.userProfileVisible ||
    modalStore.createPostVisible ||
    modalStore.likesDetailsVisible ||
    modalStore.updatePostVisible ||
    modalStore.editProfilVisible ||
    modalStore.fullSizeImageVisible ||
    modalStore.resetPasswordVisible ||
    modalStore.accountActivationVisible ||
    modalStore.submitReportVisible ||
    modalStore.sanctionAppealVisible ||
    navbarStore.overlayAccountIsOpen ||
    modalStore.blockUserVisible ||
    modalStore.selectPictures.visible ||
    modalStore.selectUserPictures.visible ||
    modalStore.videoCall.visible ||
    modalStore.answerCall.visible ||
    modalStore.premium.visible ||
    modalStore.premiumSuccess.visible ||
    modalStore.premiumError.visible ||
    modalStore.liveTerms.visible ||
    modalStore.sexualContentWarning.visible ||
    modalStore.boostModalVisible ||
    modalStore.billingAddressVisible ||
    modalStore.shoutoutModalVisible ||
    chatStore.overlayVisible ||
    viewsStore.overlayVisible ||
    favoriteStore.overlayVisible ||
    classificationStore.overlayVisible ||
    notifStore.overlayVisible;

  useEffect(() => {
    if (!overlayOpen) {
      clearAllBodyScrollLocks();
    }
  }, [overlayOpen]);

  return (
    <>
      <Hreflang />
      <Helmet htmlAttributes={{ lang: urlLanguage || "en" }}>
        <title>Bearwww</title>
      </Helmet>

      <Router basename={urlLanguage}>
        {modalStore.moderationProfilePictureVisible && (
          <ProfilePictureModeration
            visible={modalStore.moderationProfilePictureVisible}
          />
        )}
        {modalStore.signup.visible && (
          <SignupModal visible={modalStore.signup.visible} />
        )}
        {modalStore.signinVisible && (
          <SigninModal visible={modalStore.signinVisible} />
        )}
        {modalStore.moderationVisible && (
          <ModerationModal visible={modalStore.moderationVisible} />
        )}
        {modalStore.sexualContentWarning.visible && (
          <WarningModal
            visible={modalStore.sexualContentWarning.visible}
            redirectTo={modalStore.sexualContentWarning.redirectTo}
          />
        )}

        {modalStore.shoutoutModalVisible && (
          <ShoutoutModal isOpen={modalStore.shoutoutModalVisible} onClose={() => setIsShoutoutModalOpen(false)} />
        )}
        {modalStore.boostModalVisible && (
          <BoostModal isOpen={modalStore.boostModalVisible} onClose={() => setIsBoostOpen(false)}/>
        )}
        {modalStore.billingAddressVisible && (
          <BillingAddressModal isOpen={modalStore.billingAddressVisible} onClose={() => setIsBillingAddressOpen(false)}/>
        )}

        {(modalStore.userProfileVisible ||
          modalStore.isUserProfileAnonymousAndPseudo.userProfileVisible) && (
          <UserProfileModal
            visible={
              modalStore.userProfileVisible ||
              modalStore.isUserProfileAnonymousAndPseudo.userProfileVisible
            }
          />
        )}
        {modalStore.createPostVisible && (
          <CreatePostModal
            visible={modalStore.createPostVisible}
            openPhoto={modalStore.createPostOpenPhoto}
          />
        )}

        {modalStore.updatePostVisible && (
          <UpdatePostModal
            visible={modalStore.updatePostVisible}
            post={modalStore.updatePost.post}
          />
        )}
        {modalStore.fullSizeImageVisible && (
          <FullSizeImagesModal
            visible={modalStore.fullSizeImageVisible}
            images={modalStore?.fullsizeImage?.images}
            start={modalStore?.fullsizeImage?.start}
            timeLeft={modalStore?.fullsizeImage?.timeLeft}
            type={modalStore?.fullsizeImage?.type}
            isLikeable={modalStore?.fullsizeImage?.isLikeable}
          />
        )}
        {modalStore.resetPasswordVisible && (
          <ResetPasswordModal visible={modalStore.resetPasswordVisible} />
        )}
        {modalStore.accountActivationVisible && (
          <ActivationModal visible={modalStore.accountActivationVisible} />
        )}
        {modalStore.sanctionAppealVisible && (
          <SanctionAppealModal visible={modalStore.sanctionAppealVisible} />
        )}
        {navbarStore.showAccountActivationBanner && (
          <AccountActivationBanner
            visible={navbarStore.showAccountActivationBanner}
          />
        )}
        {modalStore.selectPictures && (
          <SelectPictureModal visible={modalStore.selectPictures.visible} />
        )}
        {modalStore.notification.visible && <ModalNotificationSuccess />}
        {/* {modalStore.selectUserPictures.visible && (
          <SelectUserPicturesModal visible={modalStore.selectUserPictures.visible} />
        )}
         */}
        {modalStore.videoCall.visible && (
          <VideoCallModal visible={modalStore.videoCall.visible} />
        )}
        {modalStore.answerCall.visible && (
          <AnswerCallModal visible={modalStore.answerCall.visible} />
        )}

        {modalStore.premium.visible && (
          <PremiumModal visible={modalStore.premium.visible} />
        )}
        {modalStore.premiumSuccess.visible && (
          <PremiumSuccessModal visible={modalStore.premiumSuccess.visible} />
        )}
        {modalStore.premiumError.visible && (
          <PremiumErrorModal visible={modalStore.premiumError.visible} />
        )}

        {modalStore.liveTerms.visible && (
          <LiveTermsModal visible={modalStore.liveTerms.visible} />
        )}

        {chatStore.overlayVisible && <QuickChatAccessOverlay />}
        {viewsStore.overlayVisible && <OverlayViews />}
        {favoriteStore.overlayVisible && <OverlayFavorites />}
        {classificationStore.overlayVisible && <OverlayClassification />}
        {notifStore.overlayVisible && (
          <OverlayNotifications
            visible={notifStore.overlayVisible}
            onClose={() =>
              dispatch(NotifSlice.actions.setOverlayVisibility(false))
            }
          />
        )}
        <Layout>
          {isDesktop ? <SidebarDesktop /> : <MobileSidebar />}
          <Main>
            <RoutesWrapper />
          </Main>
        </Layout>
      </Router>
    </>
  );
};

const Layout = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: row;
`;

const Main = styled.div`
  background: ${colors.darkGradient};
  background-attachment: fixed;
  margin-left: 250px;
  overflow-x: hidden;
  flex-grow: 1;
  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

export default PagesNavigation;
