import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import PagesNavigation from '../src/pages/pages.navigation';
import { store } from './store/configure.store';
import ModalSlice from './store/slices/modal.slice';
import { setMe } from './utils/auth.utils';

import './App.css';
import { OpenStreetMapsProvider } from './context/openstreetmaps';

import "./i18n";
import { BillingParamsProvider } from './context/billing-params-context';
import { languages } from './utils/locale/locale.utils';
import { updateLanguage } from './pages/settings/components/account/language-gestion.component';

const mapParamToModalAction = {
  resetPassword: ModalSlice.actions.setResetPasswordVisible(true),
  accountActivation: ModalSlice.actions.setAccountActivationVisible(true),
};

function App() {
  const [loading, setLoading] = React.useState(true);
  const [isLanguageInLocalStorage, setIsLanguageInLocalStorage] = useState<boolean>(false)

  const init = async () => {
    document.querySelector("[name='description']")?.remove();
    await setMe();
    setLoading(false);
  };

  useEffect(() => {
    init();
    if (window.localStorage.getItem("language")) {
      setIsLanguageInLocalStorage(true);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!loading) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('showModal')) {
        const storeAction = mapParamToModalAction[params.get('showModal')];
        if (storeAction) {
          store.dispatch(storeAction);
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!isLanguageInLocalStorage) return; // avoids redirection before updating the variable isLanguageInLocalStorage

    const lang = window.localStorage.getItem("language");
    const currentURL = new URL(window.location.href);
    let pathname = currentURL.pathname;
    const hasLanguagePrefix = languages.some((language) => pathname.startsWith(`/${language}/`)) || lang === 'en';

    if (hasLanguagePrefix) {
      return;
    } else {
      updateLanguage(currentURL, pathname, lang);
    }


  }, [isLanguageInLocalStorage])

  return (
    <Provider store={store}>
      <BillingParamsProvider>
        <OpenStreetMapsProvider>{loading ? <></> : <PagesNavigation />}</OpenStreetMapsProvider>
      </BillingParamsProvider>
    </Provider>
  );
}

export default App;
