import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';

import Text from '../../../components/style/text.component';
import TextInput from '../../../components/forms-v2/text.input';
import Textarea from '../../../components/forms-v2/textarea.input';
import Dropdown from '../../../components/forms/dropdown.component';
import DatetimePicker from '../../../components/forms/datetimepicker.component';
import DragDrop from '../../../components/forms/dragndrop.component';

import useLocale from '../../../utils/locale/locale.hook';
import { createGuide, updateGuide } from '../../../services/guide.service';
import { uploadFile } from '../../../utils/s3.utils';
import { validateEmail, validWebsite } from '../../../utils/regex.util';
import { trimObject } from '../../../utils/global.utils';
import fonts from '../../../themes/fonts.theme';
import { Loader } from '../../../components/style/loader.component';
import colors from '../../../themes/colors-v2.theme';
import { debouncedCitySearch } from '../../../utils/search.utils';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { UserModalTemplate } from '../../user-profile/components/user-modal.component';

enum UploadStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

enum SubmitStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

enum ModalMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
  guideToUpdate: any;
  refreshDataHandler: () => void;
}

const availableGuideTypes = [
  { value: 'event', label: 'Event', keyPhrase: 'guide.shortcuts.events' },
  {
    value: 'bar_night_club',
    label: 'Bar / Night club',
    keyPhrase: 'guide.shortcuts.bar_night_club',
  },
  {
    value: 'restaurant',
    label: 'Restaurant',
    keyPhrase: 'guide.shortcuts.restaurants',
  },
  { value: 'shop', label: 'Shop', keyPhrase: 'guide.shortcuts.shopping' },
  {
    value: 'association',
    label: 'Association',
    keyPhrase: 'guide.shortcuts.associations',
  },
  {
    value: 'accommodation',
    label: 'Accommodation',
    keyPhrase: 'guide.shortcuts.accommodations',
  },
  {
    value: 'art_culture',
    label: 'Art / Culture',
    keyPhrase: 'guide.shortcuts.art_culture',
  },
];

export default function SubmitEventModal({
  visible,
  closeModal,
  guideToUpdate,
  refreshDataHandler, // permet de rafraîchir les données du guide après sa modification
}: ModalProps) {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const locationInputRef = useRef(null);

  const [mode, setMode] = useState<ModalMode>(ModalMode.CREATE);
  const [availableTimezones, setAvailableTimezones] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.IDLE);
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState('');
  const [type, setType] = useState('event');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [city, setCity] = useState('');
  const [venue, setVenue] = useState('');
  const [initialAddress, setInitialAddress] = useState('');
  const [address, setAddress] = useState('');
  const [timeZone, setTimeZone] = useState('GTM+0');
  const [startDatetime, setStartDatetime] = useState(new Date());
  const [endDatetime, setEndDatetime] = useState(new Date());
  const [website, setWebsite] = useState('');
  const [ticket, setTicket] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [email, setEmail] = useState('');
  const [picture, setPicture] = useState({ path: null });
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<SearchResult | null>();
  const [addressAutocomplete, setAddressAutocomplete] = useState<SearchResult<RawResult>[]>([]);

  const debouncedSearch = useRef(debouncedCitySearch(setAddressAutocomplete)).current;

  const handleAddress = (address: SearchResult) => {
    setSelectedAddress(address);
    setAddress(address.label);
    setLatitude(address.raw.lat);
    setLongitude(address.raw.lon);
    setAddressAutocomplete([]);
    locationInputRef.current.value = '';
  };

  const resetLocation = () => {
    setSelectedAddress(null);
    if (locationInputRef !== null) {
      locationInputRef.current.value = '';
    }
  };

  const hasDuration = type === 'event' || type === 'accommodation' || type === 'art_culture';

  const submitHandler = () => {
    if (
      submitStatus === SubmitStatus.LOADING ||
      submitStatus === SubmitStatus.SUCCESS ||
      uploadStatus === UploadStatus.LOADING
    ) {
      return;
    }

    const body = {
      type,
      title,
      description,
      city,
      venue,
      address,
      timeZone,
      website,
      ticket,
      organizer,
      email,
      picture,
      latitude,
      longitude,
    } as any;
    trimObject(body);

    if (hasDuration) {
      body.startDatetime = startDatetime.toISOString();
      body.endDatetime = endDatetime.toISOString();
    }

    if (guideHasErrors(body)) {
      setSubmitStatus(SubmitStatus.ERROR);
      return;
    }

    const updateBody = {} as any;
    if (mode === ModalMode.UPDATE) {
      if (body.title !== guideToUpdate.title) {
        updateBody.title = body.title;
      }
      if (body.description !== guideToUpdate.description) {
        updateBody.description = body.description;
      }
      if (body.city !== guideToUpdate.city) {
        updateBody.city = body.city;
      }
      if (body.venue !== guideToUpdate.venue) {
        updateBody.venue = body.venue;
      }
      if (
        body.address !== guideToUpdate.address &&
        body.latitude !== guideToUpdate.latitude &&
        body.longitude !== guideToUpdate.longitude
      ) {
        updateBody.address = body.address;
        updateBody.latitude = body.latitude;
        updateBody.longitude = body.longitude;
      }
      if (body.website !== guideToUpdate.website) {
        updateBody.website = body.website;
      }
      if (body.ticket !== guideToUpdate.ticket) {
        updateBody.ticket = body.ticket;
      }
      if (body.organizer !== guideToUpdate.organizer) {
        updateBody.organizer = body.organizer;
      }
      if (body.email !== guideToUpdate.email) {
        updateBody.email = body.email;
      }
      if (body.timeZone !== guideToUpdate.time_zone) {
        updateBody.timeZone = body.timeZone;
      }

      if (hasDuration) {
        if (body.startDatetime !== guideToUpdate.start_datetime) {
          updateBody.startDatetime = body.startDatetime;
        }
        if (body.endDatetime !== guideToUpdate.end_datetime) {
          updateBody.endDatetime = body.endDatetime;
        }
      }

      if (picture.path) {
        updateBody.picture = picture;
      }

      if (Object.keys(updateBody).length === 0) {
        setErrorMessage(locale('guide.form.errors.unchanged_guide'));
        setSubmitStatus(SubmitStatus.ERROR);
        return;
      }
    }

    setSubmitStatus(SubmitStatus.LOADING);
    if (mode === ModalMode.CREATE) {
      createGuide(body).then((res) => {
        if (res?.success) {
          setSubmitStatus(SubmitStatus.SUCCESS);
        } else {
          setErrorMessage(locale('guide.form.errors.error_occured'));
          setSubmitStatus(SubmitStatus.ERROR);
        }
      });
    } else if (mode === ModalMode.UPDATE) {
      updateGuide(guideToUpdate.id, updateBody).then((res) => {
        if (res?.success) {
          setSubmitStatus(SubmitStatus.SUCCESS);
          refreshDataHandler();
        } else {
          setErrorMessage(locale('guide.form.errors.error_occured'));
          setSubmitStatus(SubmitStatus.ERROR);
        }
      });
    }
  };

  /**
   * Fonction permettant de vérifier si l'input actuel est satisfaisant pour la création d'un guide.
   */
  const guideHasErrors = (body: any): boolean => {
    let errorMessage = '';

    if (!body.title) {
      errorMessage = locale('guide.form.errors.title');
    }
    if (!body.description) {
      errorMessage = locale('guide.form.errors.description');
    }
    if (!body.city) {
      errorMessage = locale('guide.form.errors.city');
    }
    if (!body.venue) {
      errorMessage = locale('guide.form.errors.venue');
    }
    if (!body.latitude || !body.longitude || !body.address) {
      errorMessage = locale('guide.form.errors.address');
    }
    if (!body.organizer) {
      errorMessage = locale('guide.form.errors.organizer');
    }
    if (!validWebsite(body.website)) {
      errorMessage = locale('guide.form.errors.website');
    }
    if (!!body.ticket && !validWebsite(body.ticket)) {
      errorMessage = locale('guide.form.errors.ticket');
    }
    if (!validateEmail(body.email)) {
      errorMessage = locale('guide.form.errors.email');
    }
    if (body.picture.path === null && mode === ModalMode.CREATE) {
      errorMessage = locale('guide.form.errors.picture');
    }

    if (hasDuration) {
      if (!body.startDatetime) {
        errorMessage = locale('guide.form.errors.start');
      }
      if (!body.endDatetime) {
        errorMessage = locale('guide.form.errors.end');
      }
      if (body.startDatetime > body.endDatetime) {
        errorMessage = locale('guide.form.errors.unvalid_dates');
      }
    }

    if (!!errorMessage) {
      setErrorMessage(errorMessage);
    }
    return !!errorMessage;
  };

  const uploadPictureHandler = (files: File[]) => {
    if (uploadStatus === UploadStatus.LOADING) {
      return;
    }

    const [picture] = files;
    setUploadStatus(UploadStatus.LOADING);
    uploadFile(picture).then(({ path, error }) => {
      // DEBUG: error not handled
      setUploadStatus(UploadStatus.IDLE);
      if (path) {
        setPicture({ path });
      }
    });
  };

  const resetState = () => {
    setType('event');
    setTitle('');
    setDescription('');
    setCity('');
    setVenue('');
    setAddress('');
    setInitialAddress('');
    setTimeZone('GTM+0');
    setStartDatetime(new Date());
    setEndDatetime(new Date());
    setWebsite('');
    setTicket('');
    setOrganizer('');
    setEmail('');
    setPicture({ path: null });
    setSubmitStatus(SubmitStatus.IDLE);
  };

  // Réinitialise le formulaire lorsque le modal est caché et qu'un guide a été créé
  useEffect(() => {
    if (!visible && (submitStatus === SubmitStatus.SUCCESS || mode === ModalMode.UPDATE)) {
      resetState();
    }
  }, [visible, submitStatus]); // eslint-disable-line

  useEffect(() => {
    if (!!guideToUpdate) {
      setMode(ModalMode.UPDATE);
    } else {
      setMode(ModalMode.CREATE);
    }
  }, [guideToUpdate]);

  useEffect(() => {
    if (mode === ModalMode.UPDATE && !!guideToUpdate) {
      setType(guideToUpdate.type);
      setTitle(guideToUpdate.title);
      setDescription(guideToUpdate.description);
      setCity(guideToUpdate.city);
      setVenue(guideToUpdate.venue);
      setAddress(guideToUpdate.address);
      setLatitude(guideToUpdate.latitude);
      setLongitude(guideToUpdate.longitude);
      setInitialAddress(guideToUpdate.address);
      setTimeZone(guideToUpdate.time_zone);
      setStartDatetime(new Date(guideToUpdate.start_datetime));
      setEndDatetime(new Date(guideToUpdate.end_datetime));
      setWebsite(guideToUpdate.website);
      setTicket(guideToUpdate.ticket);
      setOrganizer(guideToUpdate.organizer);
      setEmail(guideToUpdate.email);
      setPicture({ path: null });
      setSubmitStatus(SubmitStatus.IDLE);
    }
  }, [mode, guideToUpdate]);

  // Détermine les timezones disponibles pour un guide
  useEffect(() => {
    let timezones = [
      {
        label: `GTM+0`,
        value: `GTM+0`,
      },
    ];
    for (let i = 1; i <= 12; i++) {
      timezones.push({
        label: `GTM+${i}`,
        value: `GTM+${i}`,
      });
      timezones.push({
        label: `GTM-${i}`,
        value: `GTM-${i}`,
      });
    }
    setAvailableTimezones(timezones);
  }, []);

  return (
    <UserModalTemplate
      isOpen={visible}
      onClose={closeModal}
      style={{
        borderRadius: '8px',
        background: colors.dark,
        border: `1px solid ${colors.grayBorder}`,
        zIndex: 1012,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isDesktop ? 500 : '90%',
        height: 'auto',
      }}
    >
      <ModalHeader>
        <Text>{locale('guide.form.maintitle')}</Text>
      </ModalHeader>
      <ModalContent>
        {submitStatus === SubmitStatus.SUCCESS ? (
          <SuccessWrapper>
            {locale('guide.form.success', {
              mode: mode === ModalMode.CREATE ? 'submitted' : 'updated',
            })}
          </SuccessWrapper>
        ) : (
          <>
            <DisclaimerWrapper>
              {locale('guide.form.subtitle.1')}
              <br />
              {locale('guide.form.subtitle.2')}
            </DisclaimerWrapper>
            <FormWrapper>
              {mode === ModalMode.CREATE && (
                <div>
                  <Label>{locale('guide.form.type.label')}*</Label>
                  <Dropdown
                    placeholder={locale('guide.form.type.label')}
                    value={type}
                    onSelect={(value) => setType(value)}
                    lines={availableGuideTypes}
                  />
                </div>
              )}
              <div>
                <Label>{locale('guide.form.title.label')}*</Label>
                <TextInput
                  value={title}
                  onChange={(text) => setTitle(text)}
                  placeholder={locale('guide.form.title.label')}
                />
              </div>
              <div>
                <Label>{locale('guide.form.description.label')}*</Label>
                <Textarea
                  rows={4}
                  resize="vertical"
                  value={description}
                  maxLength={10000}
                  onChange={(text) => setDescription(text)}
                  placeholder={locale('guide.form.description.label')}
                />
              </div>
              <div>
                <Label>Picture*</Label>
                <DragDrop
                  placeholder="Upload your guide's picture here ! Drag and drop your image or click here to add it manually."
                  onUpload={uploadPictureHandler}
                  allowMultipleFiles={false}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: 'calc(50% - 6px)' }}>
                  <Label>{locale('guide.form.venue.label')}*</Label>
                  <TextInput
                    value={venue}
                    onChange={(text) => setVenue(text)}
                    placeholder={locale('guide.form.venue.label')}
                  />
                </div>
                <div style={{ width: 'calc(50% - 6px)' }}>
                  <Label>{locale('guide.form.city.label')}*</Label>
                  <TextInput
                    value={city}
                    onChange={(text) => setCity(text)}
                    placeholder={locale('guide.form.city.label')}
                  />
                </div>
              </div>
              <SearchContainer>
                <SearchInputContainer>
                  <Label>{locale('guide.form.address.label')}*</Label>
                  <LocationTextInput
                    placeholder={selectedAddress?.label ? selectedAddress.label : locale('guide.form.city.label')}
                    onChange={(e) => debouncedSearch(e.target.value)}
                    ref={locationInputRef}
                  />
                  {locationInputRef.current !== null && locationInputRef?.current.value !== '' && (
                    <ResetLocationSearch
                      src="/assets/icons/sidebar/navbar/close.svg"
                      alt="cross"
                      onClick={resetLocation}
                    />
                  )}
                  {addressAutocomplete.length > 0 && (
                    <Autocomplete>
                      {addressAutocomplete.slice(0, 5).map((address) => (
                        <Line onClick={() => handleAddress(address)}>{address.label}</Line>
                      ))}
                    </Autocomplete>
                  )}
                </SearchInputContainer>
              </SearchContainer>
              <div>
                <Label>{locale('guide.form.timeZone.label')}*</Label>
                <Dropdown
                  placeholder={locale('guide.form.timeZone.label')}
                  value={timeZone}
                  onSelect={(value) => setTimeZone(value)}
                  lines={availableTimezones}
                />
              </div>
              {hasDuration && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: 'calc(50% - 6px)' }}>
                    <Label>{locale('guide.form.start.label')}*</Label>
                    <DatetimePicker value={startDatetime} onChange={(value: any) => setStartDatetime(value)} />
                  </div>
                  <div style={{ width: 'calc(50% - 6px)' }}>
                    <Label>{locale('guide.form.end.label')}*</Label>
                    <DatetimePicker value={endDatetime} onChange={(value: any) => setEndDatetime(value)} />
                  </div>
                </div>
              )}
              <div>
                <Label>{locale('guide.form.website.label')}*</Label>
                <TextInput
                  value={website}
                  onChange={(text) => setWebsite(text)}
                  placeholder={locale('guide.form.website.label')}
                />
              </div>
              <div>
                <Label>{locale('guide.form.ticket.label')}</Label>
                <TextInput
                  value={ticket}
                  onChange={(text) => setTicket(text)}
                  placeholder={locale('guide.form.ticket.label')}
                />
              </div>
              <div>
                <Label>{locale('guide.form.organizer.label')}*</Label>
                <TextInput
                  value={organizer}
                  onChange={(text) => setOrganizer(text)}
                  placeholder={locale('guide.form.organizer.label')}
                />
              </div>
              <div>
                <Label>{locale('guide.form.email.label')}*</Label>
                <TextInput
                  value={email}
                  onChange={(text) => setEmail(text)}
                  placeholder={locale('guide.form.email.example')}
                />
              </div>
            </FormWrapper>
            {mode === ModalMode.UPDATE && (
              <DisclaimerWrapper>
                If you update your guide, it will go once more into our moderation review process, and won't be
                available for other users during the review.
              </DisclaimerWrapper>
            )}
            {submitStatus === SubmitStatus.ERROR && (
              <ErrorWrapper>
                <Text>{errorMessage}</Text>
              </ErrorWrapper>
            )}

            <ButtonWrapper>
              {submitStatus === SubmitStatus.LOADING ? (
                <Loader color={colors.primary} />
              ) : (
                <Button onClick={submitHandler}>
                  <Text style={{ fontSize: '14px', textAlign: 'center' }} bodyBold>
                    {locale('guide.form.submit')}
                  </Text>
                </Button>
              )}
            </ButtonWrapper>
          </>
        )}
      </ModalContent>
    </UserModalTemplate>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 24px 0px;
  overflow-y: auto;
  max-height: 70vh;
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0px 20px;
  border-bottom: solid 1px ${colors.grayBorder};

  & > p {
    font-size: 14px;
    color: ${colors.white};
    font-weight: 500;
  }
`;

const DisclaimerWrapper = styled.div`
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 0 30px;
  font-weight: 500;

  @media (max-width: 1000px) {
    padding: 0 10px;
  }
`;

const FormWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 16px;
  }

  & input,
  textarea {
    border: 1px solid ${colors.grayBorder};
    background-color: ${colors.darkGray};
    border-radius: 8px;
    color: white;
  }

  && {
    & .react-datepicker-wrapper {
      display: block;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
`;

const Label = styled.p`
  font-size: 11px;
  margin-top: 0;
  margin-bottom: 4px;
`;

const ButtonWrapper = styled.div`
  width: 170px;
  display: flex;
  justify-content: center;
  margin: 10px auto;
`;

const ErrorWrapper = styled.div`
  margin: 10px 0;
  & > p {
    color: ${colors.lightRed};
    text-align: center;
  }
`;

const SuccessWrapper = styled.div`
  margin: 10px 0;
  text-align: center;
`;

const Button = styled.button`
  width: 160px;
  height: 32px;
  background-color: ${colors.darkBlue};
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    width: 100%;

    & input {
      width: 100%;
    }
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

const ResetLocationSearch = styled.img`
  position: absolute;
  right: 10px;
  top: 30px;
  cursor: pointer;
`;

const Autocomplete = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.darkGray};
  border-radius: 8px;
  border: 1px solid ${colors.grayBorder};
  position: absolute;
  top: 65px;
  z-index: 1000;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);

  @media (max-width: 1000px) {
    top: 60px;
    width: 93%;
  }
`;

const Line = styled.div`
  font-size: 12px;
  font-weight: ${fonts.regular.weight};
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.grayBorder};
  }
`;

const LocationTextInput = styled.input`
  border-radius: 8px;
  background-color: ${colors.white};
  color: ${colors.white};
  border: 1px solid ${colors.lightGrey9};
  padding: 12px 12px;
  outline: none;
  font-size: 12px;
  box-sizing: border-box;
  width: 100%;
`;
