import { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import colors from '../../../themes/colors-v2.theme';
import useLocale from '../../../utils/locale/locale.hook';
import { addATravel, updateATravel } from '../../../services/travel.service';
import styled from 'styled-components/macro';
import fonts from '../../../themes/fonts.theme';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { addACity, City } from '../../../services/city.service';
import { useMediaQuery } from 'react-responsive';
import Text from '../../../components/style/text.component';
import { randomString, removeAccents } from '../../../utils/string.utils';
import Datepicker from 'react-datepicker';
import { debouncedCitySearch } from '../../../utils/search.utils';
import { UserModalTemplate } from '../../user-profile/components/user-modal.component';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { Flex } from '../../../components/style/flex.component';
import { useNotification } from '../../../utils/useNotification';

interface ModalTripsProps {
  isOpen?: boolean;
  onClose: () => void;
  refresh: () => void;
  isModifyingTrip: boolean;
  setIsModifyingTrip: Dispatch<SetStateAction<boolean>>;
  trip?: Trip;
  setTrip: Dispatch<SetStateAction<Trip>>;
  getDataTrips: () => Promise<void>;
}

interface Trip {
  startDate: Date;
  endDate: Date;
  uid: string;
  city: City;
}

const ModalTrips = ({
  isOpen,
  onClose,
  refresh,
  isModifyingTrip,
  setIsModifyingTrip,
  trip,
  getDataTrips,
}: ModalTripsProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const { triggerNotification } = useNotification();

  const [startDatetime, setStartDatetime] = useState(new Date());
  const [endDatetime, setEndDatetime] = useState(new Date());
  const [selectedAddress, setSelectedAddress] = useState<SearchResult>();
  const [addressAutocomplete, setAddressAutocomplete] = useState<SearchResult<RawResult>[]>([]);
  const isFormComplete =
    isModifyingTrip ||
    (startDatetime !== undefined &&
      endDatetime !== undefined &&
      selectedAddress?.raw?.address?.city !== undefined &&
      selectedAddress?.raw?.address?.country !== undefined);

  useEffect(() => {
    if (trip) {
      setStartDatetime(new Date(trip.startDate));
      setEndDatetime(new Date(trip.endDate));
    }
  }, []);

  const debouncedSearch = useRef(debouncedCitySearch(setAddressAutocomplete)).current;

  const handleAddress = (address: SearchResult) => {
    setSelectedAddress(address);
    setAddressAutocomplete([]);
  };

  const updateTravel = async () => {
    const travelToSend = {
      travelUid: trip.uid,
      startDate: new Date(startDatetime).toISOString(),
      endDate: new Date(endDatetime).toISOString(),
    };
    await updateATravel(travelToSend);
    triggerNotification({
      visible: true,
      title: locale('travel.modified'),
    });
    setIsModifyingTrip(false);
    getDataTrips();
  };

  const handleSubmit = async () => {
    if (!isModifyingTrip) {
      const cityToSend = {
        cityName: removeAccents(selectedAddress.raw.address.city),
        cityPlaceId: randomString(27),
        countryName: removeAccents(selectedAddress.raw.address.country),
        countryPlaceId: randomString(27),
        latitude: selectedAddress.raw.lat,
        longitude: selectedAddress.raw.lon,
      };

      const res: any = await addACity(cityToSend);
      if (res.error) {
        handleSubmit();
        return;
      }
      const newCity: City = res;

      const travelToSend = {
        cityUid: newCity.uid,
        startDate: new Date(startDatetime).toISOString(),
        endDate: new Date(endDatetime).toISOString(),
      };

      await addATravel(travelToSend);

      refresh();
      triggerNotification({
        visible: true,
        title: locale('travel.added'),
      });
      onClose();
      setIsModifyingTrip(false);
      setSelectedAddress(undefined);
    } else {
      await updateTravel();
      onClose();
    }
  };

  return (
    <UserModalTemplate
      isOpen={isOpen}
      onClose={() => {
        setIsModifyingTrip(false);
        onClose();
      }}
      style={{
        borderRadius: 8,
        width: isDesktop ? '465px' : '90%',
        height: 'fit-content',
        background: colors.darkGray,
        position: isDesktop ? 'fixed' : 'absolute',
        border: `1px solid ${colors.lightGrey10}`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
      }}
    >
      <Container>
        <TitleAndClose>
          <PrimaryButton size="medium" style={{ borderColor: colors.grayBorder, width: 56, height: 56 }}>
            <img src="/assets/icons/sidebar/navbar/calendar-white.svg" width={24} height={24} />
          </PrimaryButton>
          <Text bold fontSize="16px">
            {isModifyingTrip
              ? trip.city.nameKey[0].toUpperCase() + trip.city.nameKey.substring(1)
              : locale('trips.create.title')}
          </Text>
          <Text style={{ textAlign: 'center' }}>{locale('trips.modal.description')}</Text>
        </TitleAndClose>

        <DatesContainer>
          {!isModifyingTrip && (
            <SearchInputContainer>
              <ResetLocationSearch width="14.5px" height="26px" src="/assets/icons/sidebar/navbar/explore-gray.svg" />
              <TextInput
                placeholder={
                  selectedAddress?.raw?.address?.city
                    ? selectedAddress.raw.address.city
                    : trip?.city.nameKey
                    ? trip?.city.nameKey[0].toUpperCase() + trip?.city.nameKey.substring(1)
                    : locale('guide.form.city.label')
                }
                onChange={(e) => debouncedSearch(e.target.value)}
                onBlur={(e) => (e.target.value = '')}
              />
              {addressAutocomplete.length > 0 && (
                <Autocomplete>
                  {addressAutocomplete.slice(0, 5).map((address, index) => (
                    <Line key={`${address.label}-${index}`} onClick={() => handleAddress(address)}>
                      {address.label}
                    </Line>
                  ))}
                </Autocomplete>
              )}
            </SearchInputContainer>
          )}
          <Flex gap="16px">
            <InputContainer>
              <CustomDatePicker
                selectsStart
                selected={startDatetime}
                startDate={startDatetime}
                endDate={endDatetime}
                value={startDatetime}
                onChange={(value: any) => setStartDatetime(value)}
                dateFormat="dd/MM/yyyy"
              />
            </InputContainer>
            <InputContainer>
              <CustomDatePicker
                selectsEnd
                selected={endDatetime}
                startDate={startDatetime}
                endDate={endDatetime}
                minDate={startDatetime}
                value={endDatetime}
                onChange={(value: any) => setEndDatetime(value)}
                dateFormat="dd/MM/yyyy"
              />
            </InputContainer>
          </Flex>
        </DatesContainer>
        <ButtonContainer>
          <PrimaryButton size="large" isActive disabled={!isFormComplete} onClick={() => handleSubmit()}>
            {isModifyingTrip ? locale('travel_modify_action') : locale('trips.create.bottomModal.CTA.title')}
          </PrimaryButton>
        </ButtonContainer>
      </Container>
    </UserModalTemplate>
  );
};

export default ModalTrips;

const Container = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 21px;
  border-radius: 16px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 16px 0;
  }
`;

const TitleAndClose = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const SearchInputContainer = styled.div`
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 311px;
  box-sizing: border-box;
  border-radius: 48px;
  border: 2px solid transparent;
  background: linear-gradient(to right, #d93427, #f69101, #dfd902, #14aa5f, #2d56c5, #4c2e7b) border-box;
  color: white;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & input {
    border-radius: 8px;
    background-color: ${colors.white};
    color: ${colors.darkGrey1};
    border: 1px solid ${colors.lightGrey9};

    &:hover {
      color: ${colors.darkGrey1};
    }

    &::placeholder {
      color: ${colors.darkGrey1};
    }
    &::placeholder {
      color: ${colors.darkGrey};
    }
    &:focus {
      color: ${colors.darkerGrey};
      background-color: ${colors.lightGrey4};
    }
  }
`;

const DatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 45px;
  gap: 24px;
`;

const CustomDatePicker = styled(Datepicker)`
  width: ${(props) => props.width || '100%'};
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 12px;
  box-sizing: border-box;
  color: ${colors.black};
  font-size: 12px;

  &:hover {
    color: ${colors.black};
  }

  &:focus {
    color: ${colors.black};
    background-color: ${colors.lightGrey6};
  }
`;

const ResetLocationSearch = styled.img`
  position: absolute;
  left: 10px;
  top: 5px;
  cursor: pointer;
`;

const TextInput = styled.input`
  padding-left: 36px;
  border-radius: 48px;
  background-color: ${colors.darkGray};
  color: white;
  border: 1px solid transparent;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  height: 38px;
  width: 100%;
  ::placeholder {
    color: white;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Autocomplete = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.dark};
  border-radius: 8px;
  position: absolute;
  top: 39px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border: 1px solid ${colors.grayBorder};
  z-index: 10;

  @media (max-width: 1000px) {
    width: 95%;
  }
`;

const Line = styled.div`
  font-size: 12px;
  font-weight: ${fonts.regular.weight};
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.darkGray};
  }
`;
