import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import Button from "../../../components/forms-v2/button.component";
import TextInput from "../../../components/forms-v2/text.input";
import ModalTemplate from "../../../components/miscellaneous/modal-template.component";
import Text from "../../../components/style/text.component";

import { sendResetPasswordEmail, signin } from "../../../services/auth.service";
import AuthSlice from "../../../store/slices/auth.slice";
import ModalSlice from "../../../store/slices/modal.slice";
import { setMe } from "../../../utils/auth.utils";
import useLocale from "../../../utils/locale/locale.hook";
import { validateEmail } from "../../../utils/regex.util";

import { useMediaQuery } from "react-responsive";
import Image from "../../../components/image.component";
import colors from "../../../themes/colors-v2.theme";
import fonts from "../../../themes/fonts.theme";
import Cookies from "universal-cookie";

import { getUrlLanguage } from '../../../utils/locale/hreflang.utils';
import LanguageGestion from "../../settings/components/account/language-gestion.component";

enum ModalMode {
  SIGN_IN = "SIGN_IN",
  RESET_PASSWORD = "RESET_PASSWORD",
}

enum ResetPasswordStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

interface SigninModalProps {
  visible: boolean;
}

export default function SigninModal({ visible }: SigninModalProps) {
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const locale = useLocale();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const localeStore = useSelector((state: any) => state.localeStore);
  const { signin: signinModal } = useSelector((state: any) => state.modalStore);

  const [pseudo, setPseudo] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState(ModalMode.SIGN_IN);

  const [resetStatus, setResetStatus] = useState(ResetPasswordStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState("");

  const [openLanguage, setOpenLanguage] = useState(false);
  const languageMenuRef = useRef(null);

  const submit = () => {
    if (mode === ModalMode.SIGN_IN) {
      signInHandler();
    } else {
      resetPasswordHandler();
    }
  };

  const signInHandler = async () => {
    if (loading) {
      return;
    }

    if (!pseudo.trim() || !password.trim()) {
      return;
    }

    setError(false);
    setLoading(true);

    const res = await signin(pseudo, password);

    if (!res.jwt) {
      setErrorMessage(locale("signin.error.wrong_credentials"));
      setError(true);
      setLoading(false);
      return;
    }

    if (res.sanctions?.length > 0) {
      const banned = ((res.sanctions || []) as Array<any>).find(
        (sanction: any) => sanction.type === "user_banned"
      );
      if (banned) {
        setErrorMessage(locale("signin.error.banned"));
        setError(true);
        setLoading(false);
        return;
      }
    }

    if (rememberMe) {
      const cookies = new Cookies();
      cookies.set("token", res.jwt, {
        domain: ".bearwww.com",
        path: "/",
        secure: true,
      });
      localStorage.setItem("token", res.jwt);
    }
    dispatch(AuthSlice.actions.setToken(res.jwt));
    setMe();
    setPseudo("");
    setPassword("");
    dispatch(ModalSlice.actions.setSigninVisible(false));
    if (location.pathname === "/") {
      history.push("/");
    }
    history.replace("/nearby");
    setLoading(false);
  };

  const resetPasswordHandler = async () => {
    if (
      resetStatus === ResetPasswordStatus.LOADING ||
      resetStatus === ResetPasswordStatus.SUCCESS
    ) {
      return;
    }

    if (!email.trim()) {
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage(locale("signin.reset_password.invalid_email"));
      setResetStatus(ResetPasswordStatus.ERROR);
      return;
    }

    setResetStatus(ResetPasswordStatus.LOADING);

    const res = await sendResetPasswordEmail({
      email,
      language: localeStore.language,
    });
    if (res?.success) {
      setResetStatus(ResetPasswordStatus.SUCCESS);
    } else {
      setErrorMessage(locale("signin.reset_password.something_went_wrong"));
      setResetStatus(ResetPasswordStatus.ERROR);
    }
  };

  const register = () => {
    dispatch(ModalSlice.actions.setSigninVisible(false));
    dispatch(ModalSlice.actions.setSignup({ visible: true }));
  };

  const closeModal = () => {
    dispatch(ModalSlice.actions.setSigninVisible(false));
  };

  useEffect(() => {
    if (!visible) {
      setLoading(false);
      setError(false);
      setPseudo("");
      setPassword("");
      setEmail("");
      setRememberMe(true);
      setMode(ModalMode.SIGN_IN);
      setResetStatus(ResetPasswordStatus.IDLE);
    }
  }, [visible]);

  useEffect(() => {
    setLoading(false);
    setError(false);
    setResetStatus(ResetPasswordStatus.IDLE);
  }, [mode]);

  const mapModeToButtonLabel = {
    [ModalMode.SIGN_IN]: locale("global.form.submit"),
    [ModalMode.RESET_PASSWORD]: locale("signin.reset_password.submit"),
  };

  const goBackToSeo = () => {
    const seoUrl = "https://www.bearwww.com";
    const referrerA = getUrlLanguage() ? `${seoUrl}/${getUrlLanguage()}` : seoUrl;


    if (document.referrer === referrerA) {
      window.history.back();
    } else {
        window.location.href = referrerA;
    }
  };

  return (
    <ModalTemplate
      visible={visible}
      closeModal={closeModal}
      width="100vw"
      canCloseModal={signinModal.canCloseSignin}
      closeOutside
      mobileWidth="100vw"
      position={{ top: "50%", left: "50%" }}
      style={{
        backgroundImage: "url('/assets/imgs/signin/signin.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <ModalContent>
        <SigninForm>
          <BackButton
            onClick={() =>
              mode === ModalMode.RESET_PASSWORD
                ? setMode(ModalMode.SIGN_IN)
                : goBackToSeo()
            }
            isDesktop={isDesktop}
          >
            <Image
              src="/assets/icons/nearby/chevron-right-white.svg"
              alt="Arrow back"
              width={"14px"}
              height={"14px"}
              style={{ transform: "rotate(180deg)" }}
            />
          </BackButton>

          {mode === ModalMode.RESET_PASSWORD && (
            <LoginHeader>
              <HeaderLogoWrapper>
                <Image
                  src="/assets/imgs/logo-black.svg"
                  alt="Bearwww logo"
                  width={"262px"}
                  height={"52px"}
                  style={{}}
                />
              </HeaderLogoWrapper>
            </LoginHeader>
          )}
          <FormBackgroundWrapper>
            <FormWrapper>
              {mode === ModalMode.SIGN_IN && (
                <LoginHeader>
                  <HeaderLogoWrapper>
                    <Image
                      src="/assets/icons/logo/logo-white-with-text.svg"
                      alt="Bearwww logo"
                      width={"185px"}
                      height={"35px"}
                      style={{}}
                    />
                  </HeaderLogoWrapper>
                  <HeaderTitleWrapper>
                    <Text
                      style={{ fontSize: 14, fontWeight: fonts.bold.weight }}
                      dangerouslySetInnerHTML={{
                        __html: locale(
                          "signin.header.connect_to_bears_and_their_friends"
                        ),
                      }}
                    />
                  </HeaderTitleWrapper>
                </LoginHeader>
              )}
              <InputsWrapper>
                {mode === ModalMode.SIGN_IN && (
                  <>
                    <TextInput
                      value={pseudo}
                      onChange={(text) => setPseudo(text)}
                      style={{ marginBottom: 12 }}
                      placeholder={locale("signin.placeholder.pseudo")}
                    />
                    <TextInput
                      type="password"
                      value={password}
                      onChange={(text) => setPassword(text)}
                      placeholder={locale("signin.placeholder.password")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          submit();
                        }
                      }}
                    />
                    {error && (
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                          color: colors.lightRed,
                          marginTop: 4,
                        }}
                      >
                        {errorMessage}
                      </Text>
                    )}

                    <ResetPasswordWrapper>
                      <HoverText
                        style={{
                          fontSize: 12,
                          cursor: "pointer",
                          color: colors.darkBlue,
                          fontWeight: fonts.bold.weight,
                        }}
                        onClick={() => setMode(ModalMode.RESET_PASSWORD)}
                      >
                        {locale("signin.forgot_password")}
                      </HoverText>
                    </ResetPasswordWrapper>
                  </>
                )}

                {mode === ModalMode.RESET_PASSWORD && (
                  <>
                    <Text
                      style={{
                        fontSize: 18,
                        fontWeight: fonts.bold.weight,
                        textAlign: "center",
                      }}
                    >
                      {locale("signin.reset_password.header_title")}
                    </Text>
                    <Text
                      style={{
                        marginBottom: 6,
                        marginTop: 32,
                        fontSize: 14,
                        fontWeight: fonts.semiBold.weight,
                        color: colors.darkGrey2,
                      }}
                    >
                      {locale("signin.reset_password.header_paragraph")}
                    </Text>
                    <TextInput
                      value={email}
                      onChange={(text) => setEmail(text)}
                      placeholder={locale(
                        "signin.reset_password.email_placeholder"
                      )}
                    />
                  </>
                )}
              </InputsWrapper>
              {resetStatus === ResetPasswordStatus.ERROR && (
                <ResponseWrapper>
                  <Text>{errorMessage}</Text>
                </ResponseWrapper>
              )}
              {resetStatus === ResetPasswordStatus.SUCCESS && (
                <ResponseWrapper>
                  <Text>{locale("signin.reset_password.success_message")}</Text>
                </ResponseWrapper>
              )}
              <ActionsWrapper>
                <Button
                  text={mapModeToButtonLabel[mode]}
                  loading={
                    loading || resetStatus === ResetPasswordStatus.LOADING
                  }
                  onClick={submit}
                  style={{ borderRadius: 8 }}
                />
                {mode === ModalMode.RESET_PASSWORD && (
                  <Text
                    style={{
                      marginTop: 16,
                      fontSize: 14,
                      fontWeight: fonts.bold.weight,
                      color: colors.darkBlue,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setMode(ModalMode.SIGN_IN)}
                  >
                    {locale("signin.reset_password.back-to-connexion")}
                  </Text>
                )}

                {mode === ModalMode.SIGN_IN && (
                  <NewUserWrapper>
                    <Text>{locale("signin.signup.join-us")}</Text>
                    <Text
                      onClick={register}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {locale("signin.signup.signup")}
                    </Text>
                  </NewUserWrapper>
                )}
              </ActionsWrapper>
              <div
                style={{ position: "relative", cursor: "pointer" }}
                ref={languageMenuRef}
                onClick={() => setOpenLanguage((prevState) => !prevState)}
              >
                <LanguageGestion isOpen={openLanguage} isFromSettings={false} isResponsive={false}/>
              </div>
            </FormWrapper>
          </FormBackgroundWrapper>
        </SigninForm>
      </ModalContent>
    </ModalTemplate>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ;
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 16px;

  @media (max-width: 1000px) {
    text-align: center;
    padding: 0 16px;
  }
`;

const BackButton = styled.div<{ isDesktop: boolean }>`
  position: fixed;
  top: ${({ isDesktop }) => (isDesktop ? "30px" : "50px")};
  left: ${({ isDesktop }) => (isDesktop ? "91px" : "24px")};
  cursor: pointer;
  z-index: 2;
`;

const SigninForm = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`;

const FormBackgroundWrapper = styled.div`
  position: relative;
  background: linear-gradient(
    to right,
    #d93427,
    #f69101,
    #dfd902,
    #14aa5f,
    #2d56c5,
    #4c2e7b
  );
  border-radius: 16px;
  padding: 1px;
`;

const FormWrapper = styled.div`
  position: relative;
  width: 286px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
  background-color: ${colors.dark};
  border-radius: 16px;
`;

const LoginHeader = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    line-height: normal;
    margin-bottom: 0;
  }
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & input {
    border-color: ${colors.grayBorder};
  }
`;

const ActionsWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

const NewUserWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;

  & > p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
  }
`;

const ResetPasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
`;

const ResponseWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 4px;

  & p {
    color: ${colors.darkOrange1};
  }
`;

const HoverText = styled(Text)`
  transition: color 200ms ease;

  &:hover {
    color: ${colors.primary};
  }
`;
