import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components/macro';
import { useHistory, useLocation, Route, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroller from 'react-infinite-scroller';
import LastMessagePreview from './messages/last-message-preview.component';
import FullHeightPane from '../../../components/full-height-pane/full-height-pane.component';
import { getUserConversations } from '../../../services/chat.service';
import useLocale from '../../../utils/locale/locale.hook';
import { useLogin } from '../../../utils/auth.utils';
import { getConversationInfo } from '../../../utils/chat.utils';
import { getWebsocketConnection } from '../../../utils/websocket.utils';
import { FancyScrollbar } from '../../../components/style/fancy-scrollbar.style';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';
import Notifications from '../../navbar/Notifications/Notifications';
import { getStatusColor } from '../../../utils/status.utils';
import { WoofList } from './woof-list.component';
import { openProfile } from '../../user-profile/profile.utils';
import { AlbumList } from './album-list.component';
import { CONVERSATION_PAGE_SIZE } from '../../../constants/constants';
import { Flex } from '../../../components/style/flex.component';
import Text from '../../../components/style/text.component';
import { LikesList } from './likes-list.component';
import { NoConversations } from './no-conversations.component';
import Image from '../../../components/image.component';
import { isLocalOrIntegrationEnv } from '../../../environments';

interface ComponentProps {
  selectedConversation: any;
  selectConversationHandler: (conversation: any) => void;
  newConversationUser: any;
  hasNoConversations?: boolean;
  setHasNoConversations?: React.Dispatch<React.SetStateAction<boolean>>;
  options?: {
    showExpandConversation?: boolean;
    shouldSelectFirstByDefault?: boolean;
    selectConversationById?: number;
    removeConversationById?: number;
  };
}

const UserConversations = (
  {
    selectedConversation,
    selectConversationHandler,
    newConversationUser,
    hasNoConversations = false,
    setHasNoConversations,
    options,
  }: ComponentProps,
  ref
) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const history = useHistory();
  const location = useLocation();
  const { me, isLogin } = useLogin();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const chatStore = useSelector((state: any) => state.chatStore);
  const notifStore = useSelector((state: any) => state.notifStore);
  const [activeTab, setActiveTab] = useState<'chat' | 'woof' | 'likes' | 'album'>('chat');
  const [scrollerKey, setScrollerKey] = useState(Math.random());
  const [lastConversationsMessages, setLastConversationsMessages] = useState([]);
  const [hasMoreConversations, setHasMoreConversations] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [isShoutoutModalOpen, setIsShoutoutModalOpen] = useState(false);
  const modalStore = useSelector((state: any) => state.modalStore);
  const [isBillingAddressOpen, setIsBillingAddressOpen] = useState(false);

  const getTime = (date: string) => {
    const date1 = new Date(date).getTime();
    const date2 = Date.now();
    const tmp = date2 - date1;
    const sec = Math.floor(tmp / 1000);
    const min = Math.floor(sec / 60);
    const hour = Math.floor(min / 60);
    const day = Math.floor(hour / 24);
    if (day > 0) return day.toString() + 'd';
    if (hour > 0) return hour.toString() + 'h';
    if (min > 0) return min.toString() + 'm';
    if (sec > 0) return sec.toString() + 's';
    return locale('key_now');
  };

  const handleOpenProfileOnPictureClick = (lastConversationMessage: any) => {
    if (
      !lastConversationMessage.conversation.conversation_users.find(
        (conversationUser) => conversationUser.user.pseudo !== me.pseudo
      ).user?.isAnonymous
    ) {
      openProfile(
        lastConversationMessage.conversation.conversation_users.find(
          (conversationUser) => conversationUser.user.pseudo !== me.pseudo
        ).user?.pseudo,
        false,
        false
      );
    } else {
      openProfile(
        lastConversationMessage.conversation.conversation_users.find(
          (conversationUser) => conversationUser.user.pseudo !== me.pseudo
        ).user?.anonymousPseudo,
        false,
        true
      );
    }
  };

  /**
   * Fonction permettant de récupérer les dernières conversations de l'utilisateur.
   */
  const refreshUserConversations = () => {
    setHasMoreConversations(false);
    if (setHasNoConversations) {
      setHasNoConversations(false);
    }
    setShouldResetPagination(true);
    getConversations(nextPage, CONVERSATION_PAGE_SIZE).then((lastConversationsMessages) => {
      if (Array.isArray(lastConversationsMessages)) {
        setShouldResetPagination(false);
        setHasMoreConversations(lastConversationsMessages.length === 30);
        setLastConversationsMessages(lastConversationsMessages);
        if (setHasNoConversations) {
          setHasNoConversations(lastConversationsMessages.length === 0);
        }
      }
    });
  };

  useImperativeHandle(ref, () => ({
    refreshConversations: () => {
      console.log('refresh user info')
      refreshUserConversations();
    },
  }));

  const getConversations = async (page: number | string, limit: number | string) => {
    const res = await getUserConversations(page, limit);

    if (!Array.isArray(res) || res.length === 0) {
      setHasMoreConversations(false);
      return;
    }

    const newConversations = lastConversationsMessages.concat(res);
    setLastConversationsMessages(newConversations);

    if (res.length > 0) {
      setTimeout(() => {
        setHasMoreConversations(true);
        setNextPage(typeof page === 'string' ? parseInt(page) + 1 : page + 1);
      }, 500);
    }
  };

  const getMoreConversations = async () => {
    setHasMoreConversations(false);
    getConversations(nextPage, CONVERSATION_PAGE_SIZE);
  };

  const handleWoofOpenChat = (conversationId: string) => {
    const conversation = lastConversationsMessages.find(
      (lastConversationMessage) => lastConversationMessage.conversation.id === conversationId
    ).conversation;
    selectConversationHandler(conversation);
  };

  useEffect(() => {
    if (shouldResetPagination) {
      setScrollerKey(Math.random());
    }
  }, [shouldResetPagination]);

  // nécessaire pour permettre le bon fonctionnement de la connexion WebSocket
  // DEBUG: might not work as expected...
  useEffect(() => {
    // Fonction appelée lorsqu'on reçoit un nouveau message via la connexion WebSocket.
    const onWSMessage = (e: any) => {
      const data = JSON.parse(e.data);
      if (data.type === 'chat_message') {
        refreshUserConversations();
      }
    };

    const ws = getWebsocketConnection();
    if (ws) {
      ws.removeEventListener('message', onWSMessage);
      ws.addEventListener('message', onWSMessage);
    }

    return () => {
      if (ws) {
        ws.removeEventListener('message', onWSMessage);
      }
    };
  }, []);

  useEffect(() => {
    if (isLogin) {
      refreshUserConversations();
    }
  }, [isLogin]); // eslint-disable-line

  useEffect(() => {
    if (
      selectedConversation === null &&
      lastConversationsMessages.length > 0 &&
      options?.shouldSelectFirstByDefault &&
      !options?.selectConversationById &&
      isDesktop
    ) {
      selectConversationHandler(lastConversationsMessages[0].conversation);
    }
  }, [
    lastConversationsMessages,
    selectedConversation,
    options?.shouldSelectFirstByDefault,
    options?.selectConversationById,
    selectConversationHandler,
  ]);

  useEffect(() => {
    const idToSelect = options?.selectConversationById;
    if (!!idToSelect && selectedConversation?.id !== idToSelect) {
      const lastMessage = lastConversationsMessages.find((message) => message.conversation.id === idToSelect);
      if (lastMessage) {
        selectConversationHandler(lastMessage.conversation);
      }
    }
  }, [options?.selectConversationById, lastConversationsMessages, selectedConversation, selectConversationHandler]);

  useEffect(() => {
    const idToRemove = options?.removeConversationById;
    if (idToRemove) {
      const foundLastMessage = lastConversationsMessages.find((message) => message.conversation.id === idToRemove);
      if (foundLastMessage) {
        setLastConversationsMessages((prevState) =>
          prevState.filter((message) => message.conversation.id !== idToRemove)
        );
      }
    }
  }, [options?.removeConversationById, lastConversationsMessages]);

  return (
    <FullHeightPane
      isDesktop={isDesktop}
      hasNoBorderRadius
      padding="0"
      height={!isDesktop ? 'calc(100vh - 130px)' : '100vh'}
    >
      <Header>
        <Flex gap="16px" alignItems="center">
          <img src="/assets/icons/sidebar/navbar/message-white.svg" alt="message" width={24} height={24} />
          <Title>{locale('title_messages')}</Title>
        </Flex>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {/* Shout-out button silenced until further notice from Alain  */}
          {/* <PrimaryButton
            size="large"
            isActive
            style={{ height: 36, padding: '8px 14px' }}
            onClick={() => dispatch(ModalSlice.actions.setShoutoutModalVisible(true))}
          >
            <img src="/assets/icons/messages/shout-out.svg" alt="shout-out" width={20} height={20} />
            <Text bold style={{ marginLeft: 4 }}>
              {locale('messages.shoutout')}
            </Text>
          </PrimaryButton> */}
        </div>
      </Header>
      <Row>
        <FilterButton active={activeTab === 'chat'} onClick={() => setActiveTab('chat')}>
          {locale('android.chat.title')}
          {notifStore.messagesNotSeen > 0 && (
            <NotificationContainer>
              <Notifications
                notificationNumber={notifStore.messagesNotSeen}
                maxNumberOfChar={1}
                position={'inline-block'}
              />
            </NotificationContainer>
          )}
        </FilterButton>
        <FilterButton active={activeTab === 'woof'} onClick={() => setActiveTab('woof')}>
          {locale('profile.woof.woof')}
          {notifStore.favoritesNotSeen > 0 && (
            <NotificationContainer>
              <Notifications notificationNumber={notifStore.favoritesNotSeen} maxNumberOfChar={1} />
            </NotificationContainer>
          )}
        </FilterButton>
        {!isDesktop ? (
          <FilterButton active={activeTab === 'album'} onClick={() => setActiveTab('album')}>
            {locale('gallery.see.albums')}
            {notifStore.favoritesNotSeen > 0 && (
              <NotificationContainer>
                <Notifications notificationNumber={notifStore.favoritesNotSeen} maxNumberOfChar={1} />
              </NotificationContainer>
            )}
          </FilterButton>
        ) : null}
        <FilterButton active={activeTab === 'likes'} onClick={() => setActiveTab('likes')}>
          {locale('message.likes.title')}
          {notifStore.favoritesNotSeen > 0 && (
            <NotificationContainer>
              <Notifications notificationNumber={notifStore.favoritesNotSeen} maxNumberOfChar={1} />
            </NotificationContainer>
          )}
        </FilterButton>
      </Row>
      {activeTab === 'chat' && (
        <ConversationsWrapper key={scrollerKey}>
          {hasNoConversations && !newConversationUser ? (
            <NoConversations
              iconePath="/assets/icons/sidebar/navbar/message-white.svg"
              title={locale('messages.empty.chat')}
            />
          ) : (
            <>
              {!!newConversationUser && (
                <Route exact path="/messages/new">
                  <Conversation selected={true}>
                    <ConversationPicture>
                      {/* DEBUG: all those pending files should in fact be one component */}
                      <Image
                        alt=""
                        src={
                          newConversationUser?.profilePicture?.path ||
                          'https://d2tiq3wo24jtl5.cloudfront.net/pictures/default_profile_picture.png'
                        }
                        width={'24px'}
                      />
                    </ConversationPicture>
                    <ConversationInfo>
                      <div className="name">
                        <span>
                          {locale('chat.conversations.new_message')}{' '}
                          {newConversationUser?.pseudo ? `(${newConversationUser.pseudo})` : ''}
                        </span>
                      </div>
                    </ConversationInfo>
                  </Conversation>
                </Route>
              )}
              <InfiniteScroller
                pageStart={0}
                loadMore={getMoreConversations}
                hasMore={hasMoreConversations}
                useWindow={false}
                initialLoad={false}
              >
                {lastConversationsMessages.map((lastConversationMessage) => {
                  const { conversationImageUrl, conversationName, conversationStatus } = getConversationInfo(
                    me,
                    lastConversationMessage.conversation
                  );
                  return (
                    <Conversation
                      key={lastConversationMessage.id}
                      selected={isDesktop && selectedConversation?.id === lastConversationMessage.conversation.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (location.pathname.includes('/messages/new')) {
                          history.push('/messages');
                        }
                        selectConversationHandler(lastConversationMessage.conversation);
                      }}
                    >
                      <ConversationInnerContainer
                        selected={isDesktop && selectedConversation?.id === lastConversationMessage.conversation.id}
                      >
                        <ConversationPicture
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenProfileOnPictureClick(lastConversationMessage);
                          }}
                        >
                          {/* DEBUG: all those pending files should in fact be one component */}
                          <Image
                            alt=""
                            src={
                              conversationImageUrl ||
                              'https://d2tiq3wo24jtl5.cloudfront.net/pictures/default_profile_picture.png'
                            }
                            width={'24px'}
                          />
                        </ConversationPicture>
                        <ConversationInfo>
                          <Flex alignItems="center" justify="space-between">
                            <Flex alignItems="center">
                              {!!conversationStatus && (
                                <StatusCircle color={getStatusColor(conversationStatus).centerCircle} />
                              )}
                              <Text
                                bold
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '180px',
                                }}
                              >
                                {conversationName}
                              </Text>
                            </Flex>
                            <Text fontSize="10px">{`${getTime(lastConversationMessage.createdAt)}`}</Text>
                          </Flex>
                          <Flex alignItems="center" justify="space-between">
                            <LastMessagePreview
                              message={lastConversationMessage}
                              active={!!chatStore.messagesNotSeen[lastConversationMessage.conversation.id]}
                            />
                            {!!chatStore.messagesNotSeen[lastConversationMessage.conversation.id] && (
                              <NotificationContainer>
                                <Notifications
                                  style={{ marginRight: '0px' }}
                                  notificationNumber={
                                    chatStore.messagesNotSeen[lastConversationMessage.conversation.id]
                                  }
                                  maxNumberOfChar={1}
                                  position={'inline-block'}
                                />
                              </NotificationContainer>
                            )}
                          </Flex>
                        </ConversationInfo>
                        <ConversationActivity></ConversationActivity>
                      </ConversationInnerContainer>
                    </Conversation>
                  );
                })}
              </InfiniteScroller>
            </>
          )}
        </ConversationsWrapper>
      )}
      {activeTab === 'woof' && <WoofList onChatClick={handleWoofOpenChat} />}
      {activeTab === 'album' && <AlbumList />}
      {activeTab === 'likes' && <LikesList />}
    </FullHeightPane>
  );
};

const Header = styled.div`
  font-size: 10px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.backgroundLight};
  letter-spacing: 0.6px;
  padding: 20px 16px 0 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConversationsWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${FancyScrollbar}

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Conversation = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  padding: 10.5px;
  margin: 4px;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? '#106BF21A' : 'inherit')};
  position: relative;

  &:hover {
    background-color: rgba(73, 78, 84, 0.1);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${colors.graySeparator};
  }
`;

const ConversationInnerContainer = styled.div<{ selected?: boolean }>`
  width: 100%;
  user-select: none;
  display: flex;
  box-sizing: border-box;
  border-radius: 4px;
  transition: background-color 200ms ease;
  position: relative;
`;

const ConversationPicture = styled.div`
  width: 76px;
  height: 76px;
  position: relative;
  border-radius: 4px;

  & > img {
    object-fit: cover;
    width: 76px;
    height: 76px;
    border-radius: 4px;
    filter: ${isLocalOrIntegrationEnv ? 'blur(20px)': 'blur(0px)'};
    -webkit-filter: ${isLocalOrIntegrationEnv ? 'blur(20px)': 'blur(0px)'};
  }
`;

const ConversationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 12px;
  box-sizing: border-box;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  & .name {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    color: ${colors.lightGrey9};
    margin-bottom: 6px;
  }
`;

const ConversationActivity = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-weight: ${fonts.bold.weight};
  font-size: 16px;
  margin: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px 0 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.graySeparator};
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: 110px;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : 'white')};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.darkBlue}` : `2px solid transparent`)};
`;

const NotificationContainer = styled.div`
  width: fit-content;
  display: inline-block;
  margin-left: 4px;

  @media (max-width: 1000px) {
    top: 12px;
    left: calc(50% - 14px + 35px);
  }
`;

const StatusCircle = styled.div<{ color: string }>`
  background-color: ${(props) => (props.color === '#000000' ? 'transparent' : props.color)};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #2a2c33;
  margin-right: 6px;
`;

export default React.memo(forwardRef(UserConversations));
