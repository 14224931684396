import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import { startVideoCall } from "../../../services/video-call.service";
import ModalSlice from "../../../store/slices/modal.slice";
import { useLogin } from "../../../utils/auth.utils";
import { getConversationInfo } from "../../../utils/chat.utils";
import { useOnClickOutside } from "../../../utils/clickOutside.utils";
import useLocale from "../../../utils/locale/locale.hook";
import { openProfile } from "../../user-profile/profile.utils";

import BlockUserModal from "../../../components/miscellaneous/modals/block-user.modal";
import Text from "../../../components/style/text.component";
import fonts from "../../../themes/fonts.theme";

import SubmitReportModal from "../../../components/miscellaneous/modals/submit-report.modal";
import { Flex } from "../../../components/style/flex.component";
import { getConversationMedias } from "../../../services/chat.service";
import colors from "../../../themes/colors-v2.theme";
import { getStatusColor } from "../../../utils/status.utils";
import DeleteConversationModal from "../modals/delete-conversation.modal";
import VideoCallErrorModal from "../modals/video-call-error.modal";
import { MobileDisplay } from "../types/mobile-display.enum";
import Image from "../../../components/image.component";
import { isLocalOrIntegrationEnv } from "../../../environments";

interface ComponentProps {
  conversation: any;
  newConversationUser: any;
  setMobileDisplay?: React.Dispatch<React.SetStateAction<MobileDisplay>>;
  canCloseConversation: boolean;
  closeConversationHandler: () => void | undefined;
  optionsRef?: any;
  fromProfile?: boolean;
  GoBackToProfile?: () => void;
  refreshConversationList?: () => void;
  messageMode?: boolean;
}

const ModalDisplay = (props) => {
  switch (props.toRender) {
    case "block":
      return (
        <BlockUserModal visible={true} onClose={() => props.setToRender("")} />
      );
    default:
      return <> </>;
  }
};

export default function ConversationHeader({
  conversation,
  newConversationUser,
  setMobileDisplay,
  canCloseConversation,
  closeConversationHandler,
  optionsRef,
  fromProfile,
  GoBackToProfile,
  refreshConversationList,
  messageMode,
}: ComponentProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const locale = useLocale();
  const { me } = useLogin();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const modalStore = useSelector((state: any) => state.modalStore);

  const [showVideoCallErrorModal, setShowVideoCallErrorModal] = useState(false);
  const [videoCallErrorMessage, setVideoCallErrorMessage] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [showDeleteConversationModal, setShowDeleteConversationModal] =
    useState(false);
  const [toRender, setToRender] = useState("");
  const [conversationMedias, setConversationMedias] = useState([]);
  const [hasMoreMedias, setHasMoreMedias] = useState(false);
  const history = useHistory();

  const optionsSelectorRef = useRef(null);

  useOnClickOutside([optionsSelectorRef], () => {
    setShowOptions(false);
  });

  const openOptionsHandler = () => {
    setShowOptions(true);
  };

  const openFullSizeImages = (conversationMedias: any) => {
    dispatch(
      ModalSlice.actions.setFullSizeImageVisibleVisible({
        visible: true,
        images: conversationMedias,
        start: 0,
        type: "pictures",
      })
    );
  };

  const handleMediasOption = () => {
    getConversationMedias(conversation.id, 0)
      .then((medias) => {
        if (Array.isArray(medias)) {
          setHasMoreMedias(medias.length >= 10);
          setConversationMedias(medias);
        }
      })
      .finally(() => {
        if (conversationMedias.length > 0) {
          openFullSizeImages(conversationMedias);
        }
      });
  };

  const handleDeleteOption = () => {
    setShowDeleteConversationModal(true);
  };

  const handleReportOption = () => {
    dispatch(
      ModalSlice.actions.setSubmitReport({
        visible: true,
        type: "user",
        key: firstConversationUser.id,
      })
    );
  };

  const handleBlockOption = () => {
    dispatch(
      ModalSlice.actions.setBlockUser({
        visible: false,
        userId: firstConversationUser.id,
      })
    );
    setToRender("block");
  };
  // console.log('wepoifjweoifj');

  // useEffect(() => {
  //   const handleNavigation = (event) => {
  //     event.preventDefault();
  //     if (event.persisted) {
  //       event.preventDefault();
  //       setMobileDisplay(MobileDisplay.CONVERSATIONS);
  //     }
  //   };
  //   window.addEventListener('pageshow', handleNavigation);

  //   return () => {
  //     window.removeEventListener('pageshow', handleNavigation);
  //   };
  // }, []);

  const {
    conversationImageUrl,
    conversationName,
    conversationStatus,
    firstConversationUser,
  } = getConversationInfo(me, conversation);

  const isAdmin =
    firstConversationUser && firstConversationUser.role === "admin";

  const horizontalPadding = location.pathname.includes("/messages")
    ? "24px"
    : "12px";

  return (
    <HeaderWrapper horizontalPadding={horizontalPadding}>
      <ModalDisplay
        toRender={toRender}
        setToRender={setToRender}
        ref={optionsRef}
      />
      <SubmitReportModal
        visible={modalStore.submitReportVisible}
        style={{ width: "inherit" }}
      />
      {!isDesktop && !!setMobileDisplay && (
        <div
          className="mobileGoBack"
          onClick={() => {
            setMobileDisplay(MobileDisplay.CONVERSATIONS);
            history.goBack();
          }}
        >
          <img alt="" src="/assets/icons/previous-outlined-white.svg" />
        </div>
      )}
      {showOptions && (
        <OptionsDiv ref={optionsSelectorRef}>
          <OptionList>
            <OptionListItem>
              <OptionButton
                onClick={() => {
                  handleMediasOption();
                  setShowOptions(false);
                }}
              >
                {locale("chat.display_medias")}
              </OptionButton>
            </OptionListItem>
            {!isAdmin && (
              <OptionListItem>
                <OptionButton
                  onClick={() => {
                    handleDeleteOption();
                    setShowOptions(false);
                  }}
                >
                  {locale("chat.delete_conversation")}
                </OptionButton>
              </OptionListItem>
            )}

            {!isAdmin && (
              <OptionListItem>
                <OptionButton
                  onClick={() => {
                    handleReportOption();
                    setShowOptions(false);
                  }}
                >
                  {locale("chat.report_user")}
                </OptionButton>
              </OptionListItem>
            )}
            {!isAdmin && (
              <OptionListItem>
                <OptionButton
                  onClick={() => {
                    handleBlockOption();
                    setShowOptions(false);
                  }}
                >
                  {locale("chat.block_user")}
                </OptionButton>
              </OptionListItem>
            )}
          </OptionList>
        </OptionsDiv>
      )}
      {fromProfile ? (
        <>
          <BackToProfile onClick={() => GoBackToProfile()}>
            <img
              alt=""
              src="/assets/icons/user-profile/icons-navigation-angle-left.svg"
            />
          </BackToProfile>
          <div
            className="picture"
            onClick={() => {
              if (conversation.type === "private" && !isAdmin) {
                openProfile(conversationName);
              }
            }}
          >
            {!!newConversationUser && (
              <Image
                alt=""
                src={
                  newConversationUser?.profilePicture?.path ||
                  "https://d2tiq3wo24jtl5.cloudfront.net/pictures/default_profile_picture.png"
                }
                width={"24px"}
                height={"24px"}
              />
            )}
            {!!conversation && (
              <Image
                alt=""
                src={
                  conversationImageUrl ||
                  "https://d2tiq3wo24jtl5.cloudfront.net/pictures/default_profile_picture.png"
                }
                width={"24px"}
                height={"24px"}
              />
            )}
          </div>
          <Flex
            alignItems="flex-start"
            style={{ alignSelf: "start" }}
            className="name"
            onClick={() => {
              if (conversation.type === "private" && !isAdmin) {
                openProfile(conversationName);
              }
            }}
          >
            {!isAdmin && (
              <StatusCircle
                color={getStatusColor(conversationStatus).centerCircle}
              />
            )}
            <Flex alignItems="flex-start">
              {!!newConversationUser && (
                <span>{newConversationUser.pseudo}</span>
              )}
              {!!conversation && (
                <span
                  style={{
                    cursor:
                      conversation.type === "private"
                        ? isAdmin
                          ? "auto"
                          : "pointer"
                        : "auto",
                  }}
                >
                  <Text bold>{conversationName}</Text>
                </span>
              )}
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          {canCloseConversation && !isDesktop && (
            <CloseConversationWrapper
              className="action"
              onClick={closeConversationHandler}
            >
              <img
                alt=""
                width="14px"
                height="14px"
                src={"/assets/icons/messages/arrow-left.svg"}
                style={{ marginRight: 7 }}
              />
            </CloseConversationWrapper>
          )}
          <div
            className="picture"
            onClick={() => {
              if (conversation.type === "private" && !isAdmin) {
                openProfile(conversationName);
              }
            }}
          >
            {!!newConversationUser && (
              <Image
                alt=""
                src={
                  newConversationUser?.profilePicture?.path ||
                  "https://d2tiq3wo24jtl5.cloudfront.net/pictures/default_profile_picture.png"
                }
                width={"24px"}
                height={"24px"}
              />
            )}
            {!!conversation && (
              <Image
                alt=""
                src={
                  conversationImageUrl ||
                  "https://d2tiq3wo24jtl5.cloudfront.net/pictures/default_profile_picture.png"
                }
                width={"24px"}
                height={"24px"}
              />
            )}
          </div>
          <Flex
            alignItems="flex-start"
            style={{ alignSelf: "start", marginTop: 12 }}
            className="name"
            onClick={() => {
              if (conversation.type === "private" && !isAdmin) {
                openProfile(conversationName);
              }
            }}
          >
            {!isAdmin && (
              <StatusCircle
                color={getStatusColor(conversationStatus).centerCircle}
              />
            )}
            <Flex alignItems="flex-start">
              {!!newConversationUser && (
                <span>{newConversationUser.pseudo}</span>
              )}
              {!!conversation && (
                <span
                  style={{
                    cursor:
                      conversation.type === "private"
                        ? isAdmin
                          ? "auto"
                          : "pointer"
                        : "auto",
                  }}
                >
                  <Text bold>{conversationName}</Text>
                </span>
              )}
            </Flex>
          </Flex>
        </>
      )}
      <ActionsWrapper messageMode={messageMode}>
        <div className="action" onClick={openOptionsHandler}>
          <img
            alt=""
            width="24px"
            height="24px"
            src="/assets/icons/user-profile/flag-report-blue.svg"
          />
        </div>
        {canCloseConversation && isDesktop && (
          <CloseConversationWrapper
            className="action"
            onClick={closeConversationHandler}
          >
            <img
              alt=""
              width="10px"
              height="10px"
              src={"/assets/icons/messages/icons-actions-close-dark.svg"}
            />
          </CloseConversationWrapper>
        )}
      </ActionsWrapper>
      <VideoCallErrorModal
        visible={showVideoCallErrorModal}
        closeModal={() => setShowVideoCallErrorModal(false)}
        errorMessage={videoCallErrorMessage}
      />
      <DeleteConversationModal
        visible={showDeleteConversationModal}
        closeModal={() => setShowDeleteConversationModal(false)}
        conversation={conversation}
        deleteCallback={() => {
          closeConversationHandler?.();
          refreshConversationList && refreshConversationList();
        }}
      />
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div<{ horizontalPadding: string }>`
  height: 70px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 12px ${(p) => p.horizontalPadding} 12px;
  background: ${colors.darkGradient};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  gap: 12px;

  & > .mobileGoBack {
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 20px;
      height: 20px;
    }
  }

  & > .picture {
    aspect-ratio: 1;
    width: undefined;
    height: 100%;
    border-radius: 4px;
    position: relative;
    filter: ${isLocalOrIntegrationEnv ? 'blur(10px)': 'blur(0px)'};
    -webkit-filter: ${isLocalOrIntegrationEnv ? 'blur(10px)': 'blur(0px)'};

    & img {
      object-fit: cover;
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }
  }

  & .name {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    color: ${colors.darkerGrey};

    & .activity {
      font-size: 12px;
      font-family: ${fonts.regular.name};
      font-weight: ${fonts.regular.weight};
      color: ${colors.darkerGrey};
    }
  }
`;

const ActionsWrapper = styled.div<{ messageMode: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  ${({ messageMode }) => messageMode && "margin-right: 25px"};

  & > .action {
    cursor: pointer;
    margin-left: 12px;
    width: 25px;
    height: 25px;
  }
`;

const CloseConversationWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;

  display: grid;
  place-items: center;
`;

const OptionsDiv = styled.div`
  position: absolute;
  display: flex;
  width: 244px;
  top: 52px;
  right: 46px;
  background-color: ${colors.darkGray};
  border-radius: 4px;
  list-style: none;
  z-index: 2;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
`;

const OptionList = styled.ul`
  list-style: none;
  padding: 4px 0;
  margin: 0;
  z-index: 1;
`;

const OptionListItem = styled.li`
  padding: 0px 4px;
`;

const OptionButton = styled.button`
  width: 236px;
  height: 30px;
  cursor: pointer;
  background-color: ${colors.darkGray};
  padding-left: 9px;
  border-radius: 4px;
  border: none;
  color: ${colors.white};
  text-align: start;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  font-size: 12px;
  &:hover {
    background-color: ${colors.greyDarkHover};
  }
`;

const BackToProfile = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;

  & > img {
    width: 8px;
    height: 12px;
    margin-right: 16px;
    margin-top: 2px;
  }

  & > p {
    margin: 0;
    font-family: ${fonts.bold.name};
    font-weight: ${fonts.bold.weight};
    font-size: 12px;
  }
`;

const StatusCircle = styled.div<{ color: string }>`
  background-color: ${(props) =>
    props.color === "#000000" ? "transparent" : props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #2a2c33;
  margin-right: 6px;
`;
