import { environment } from "../environments";
import { store } from "../store/configure.store";

export const queryBuilder = (query) => {
  if (!query) return;
  const encodedQuery = `?${Object.keys(query)
    .map((key) => `${key}=${JSON.stringify(query[key])}`)
    .join("&")}`;
  return encodedQuery;
};

export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "x-source": "web",
  "bearwww-api-version": "1",
};

export const put = async (route, object): Promise<globalThis.Response> => {
  const token = store.getState().authStore.token;

  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = token;
  }
  return await fetch(environment.apiUrl + route, {
    method: "put",
    headers,
    body: JSON.stringify(object),
  });
};

export const get = async (route) => {
  const token =
    store.getState().authStore.token || window.localStorage.getItem("token");

  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = token;
  }
  return await fetch(environment.apiUrl + route, {
    method: "get",
    headers,
  });
};

export const del = async (route, object = {}) => {
  const token = store.getState().authStore.token;

  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = token;
  }
  return await fetch(environment.apiUrl + route, {
    method: "delete",
    headers,
    body: JSON.stringify(object),
  });
};

export const post = async (route, object) => {
  const token = store.getState().authStore.token;

  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = token;
  }
  return await fetch(environment.apiUrl + route, {
    method: "post",
    headers,
    body: JSON.stringify(object),
  });
};
